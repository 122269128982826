import API from "./api";
//import session from "../session";

const GetOtp = async (type, input, templateid) => {
    try {
        let res = await API.get(`common/${type}/${input}/otp`);
        if (templateid) {
            res = await API.get(`common/${type}/${input}/otp/${templateid}`);
        }
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const ValidateOtp = async (type, input, otp) => {
    try {
        const res = await API.get(`common/${type}/${input}/validateotp/${otp}`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const Contact = async (input) => {
    try {
        const res = await API.post(`common/contact`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const AddEnquiry = async (input) => {
    try {
        const res = await API.post(`common/enquiry`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const QuickApply = async (input) => {
    try {
        const res = await API.post(`common/quickapply`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const CreditScore = async (input) => {
    try {
        const res = await API.post(`common/creditscore`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const AddReviews = async (input) => {
    try {
        const res = await API.post(`common/review`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetReviews = async () => {
    try {
        const res = await API.get(`common/review`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetDocumentTypes = async (type) => {
    try {
        const res = await API.get(`common/docproofs/${type}`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export {
    GetOtp, Contact, AddEnquiry, QuickApply, CreditScore, ValidateOtp,
    AddReviews, GetReviews, GetDocumentTypes
};
