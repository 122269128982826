import pdfmake from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Helper } from "common";
// import logoImage from "../content/img/logo.jpg";


// Set the fonts
pdfmake.vfs = pdfFonts.pdfMake.vfs;
// Function to format the date and time
function getCurrentDateTime() {
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    let hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();
    const amOrPm = hours >= 12 ? 'pm' : 'am';

    // Convert hours to 12-hour format
    hours %= 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    // Format the date and time
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${amOrPm}`;
    const formattedDate = `${day}-${month}-${year}`

    const date = {
        formattedDateTime: formattedDateTime,
        formattedDate: formattedDate
    }
    return date;
}

// Get the current date and time
const { formattedDateTime, formattedDate } = getCurrentDateTime();

const pdfGenerator = async (loanData) => {
    try {

        const tableBody = [];
        // Loop through transInfo and populate the table body
        loanData.transInfo.forEach(transaction => {
            const row = [
                transaction.transDate || '', // Check for undefined and assign an empty string if needed
                transaction.transNo || '',
                transaction.particulars || '',
                transaction.debit !== undefined ? Helper.INRCurrencyFormat(transaction.debit) : '', // Check for undefined debit
                transaction.credit !== undefined ? Helper.INRCurrencyFormat(transaction.credit) : '', // Check for undefined credit
                transaction.balance !== undefined ? transaction.balance : '' // Check for undefined balance
            ];
            tableBody.push(row);
        });

        let docDefinition = {
            footer: function (currentPage, pageCount) {
                return {
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                { text: '', border: [0, 0, 0, 1], }
                            ],
                            [
                                { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', fontSize: 8, }
                            ],
                        ]
                    },
                    layout: {
                        defaultBorder: false,
                    },
                    margin: [30, 0, 30, 0],
                }
            },
            content: [
                // {
                //     // You can use a data URI directly if logoImage is a base64 encoded image
                //     image: logoImage,
                //     width: 35,
                //     height: 35,
                //     alignment: 'center',
                // },
                {
                    text: 'MOHAM CAPITAL PVT LTD\n',
                    style: 'header',
                },
                {
                    text: 'REG.OFFICE: (#10,5TH CROSS,16TH MAIN,KSRTC LAYOUT,JP NAGAR II PHASE,BANGALORE,KARNATAKA-560078)\n',
                    style: 'subheader',
                },
                {
                    text: 'BRANCH OFFICE: (#10,5TH CROSS,16TH MAIN , K.S.R.T.C LAYOUT, JP NAGAR,II PHASE,BANGALORE ,\n',
                    style: 'subheader',
                },
                {
                    text: 'KARNATAKA-560078)\n\n',
                    style: 'subheader',
                },
                {
                    text: `ACCOUNT STATEMENT AS ON : ${formattedDate}`,
                    style: 'header',
                },
                {
                    text: loanData.loaninfo.loanNumber + "_" + loanData.userInfo.borrowerName,
                    style: 'header',
                },
                {
                    columns: [
                        {
                            text: `Printed On: ${formattedDateTime}`,
                            style: 'subheader',
                            fontSize: 9,
                            alignment: 'left'
                        },
                        {
                            text: 'BRANCH : JP NAGAR',
                            style: 'subheader',
                            fontSize: 9,
                            alignment: 'left',
                            margin: [160, 0, 0, 0],
                        }
                    ]
                },
                {
                    margin: [0, 0, 0, 0],
                    table: {
                        widths: [514, '*', '*'],
                        heights: 20,
                        body: [
                            [
                                {
                                    text: 'CONTACT DETAILS',
                                    style: 'visit'
                                },
                            ],
                        ], layout: {
                            hLineWidth: function (i, node) {
                                // Set the bottom border width to 0 for all rows
                                return (i === node.table.body.length) ? 0 : 1;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                        },
                    },
                },
                {
                    style: 'tableExample',
                    margin: [0, 0, 0, 10],
                    table: {
                        widths: [514, '*', '*'],
                        body: [
                            [
                                {
                                    stack: [
                                        {
                                            columns: [
                                                {
                                                    width: 70,
                                                    text: 'Name',
                                                    style: 'headerData',
                                                    bold: true,
                                                },
                                                {
                                                    width: '*',
                                                    text: ':   ',
                                                    bold: true,
                                                }, {
                                                    width: 200,
                                                    text: loanData.userInfo.borrowerName, style: 'headerData', bold: true, margin: [4, 0, 0, 0],
                                                },
                                                {
                                                    width: 70,
                                                    text: 'Mobile No', style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: ':   '
                                                },
                                                {
                                                    width: 200,
                                                    text: loanData.userInfo.phoneNo, style: 'headerData', margin: [4, 0, 0, 0],
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 70,
                                                    text: 'Father Name', style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: ':  '
                                                }, {
                                                    width: 200,
                                                    text: loanData.userInfo.fatherName, style: 'headerData', margin: [4, 0, 0, 0],
                                                },
                                                {
                                                    width: 70,
                                                    text: 'Aadhar No', style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: ':  '
                                                },
                                                {
                                                    width: 200,
                                                    text: loanData.userInfo.aadharno, style: 'headerData', margin: [4, 0, 0, 0],
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 70,
                                                    text: 'Email ID', style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: ':  '
                                                }, {
                                                    width: 200,
                                                    text: loanData.userInfo.emailid, style: 'headerData', margin: [4, 0, 0, 0],
                                                },
                                                {
                                                    width: 70,
                                                    text: 'PAN No', style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: ':  '
                                                },
                                                {
                                                    width: 200,
                                                    text: loanData.userInfo.panno, style: 'headerData', margin: [4, 0, 0, 0],
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 70,
                                                    text: 'Address', style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: ': '
                                                }, {
                                                    width: 200,
                                                    text: loanData.userInfo.address, style: 'headerData', margin: [4, 0, 0, 0],
                                                },
                                                {
                                                    width: 70,
                                                    text: ''
                                                },
                                                {
                                                    width: '*',
                                                    text: ''
                                                },
                                                {
                                                    width: 200,
                                                    text: ""
                                                }
                                            ]
                                        },
                                    ],
                                    margin: [12, 12, 0, 12],

                                },
                            ],
                        ],
                    },
                    layout: {
                        hLineColor: function (i) {
                            return i === 0 ? 'white' : 'black'; // Set the top border color to white
                        },
                        vLineColor: function (i) {
                            return i === 0 ? 'black' : 'black';
                        },
                    },
                },
                {
                    margin: [0, 0, 0, 0],
                    table: {
                        widths: [514, '*', '*'],
                        heights: 20,
                        body: [
                            [
                                {
                                    text: 'LOAN DETAILS',
                                    style: 'visit',
                                },
                            ],
                        ],
                        layout: {
                            hLineWidth: function (i, node) {
                                // Set the bottom border width to 0 for all rows
                                return (i === node.table.body.length) ? 0 : 1;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                        },
                    },
                },
                {
                    style: 'tableExample',
                    margin: [0, 0, 0, 10],
                    table: {
                        widths: [514, '*', '*'],
                        body: [
                            [
                                {
                                    stack: [
                                        {
                                            columns: [
                                                {
                                                    width: 70,
                                                    text: 'Loan No',
                                                    style: 'headerData',
                                                    bold: true,
                                                },
                                                {
                                                    width: '*',
                                                    text: ':   ',
                                                    bold: true,
                                                    margin: [0, 0, 0, -140]
                                                }, {
                                                    width: 200,
                                                    text: loanData.loaninfo.loanNumber, style: 'headerData',
                                                    margin: [4, 0, 0, 0],
                                                    bold: true,
                                                },
                                                {
                                                    width: 120,
                                                    text: 'Instalment Amount', style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: ':   '
                                                },
                                                {
                                                    width: 200,
                                                    text: loanData.loaninfo.emi, style: 'headerData',
                                                    margin: [4, 0, 0, 0],
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 70,
                                                    text: 'Loan Amount',
                                                    style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: ':   ',
                                                }, {
                                                    width: 200,
                                                    text: Helper.INRCurrencyFormat(loanData.loaninfo.loanAmount), style: 'headerData', margin: [4, 0, 0, 0],
                                                },
                                                {
                                                    width: 120,
                                                    text: 'First Instalment Date', style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: ':   '
                                                },
                                                {
                                                    width: 200,
                                                    text: Helper.ToDate(loanData.loaninfo.firstinstalmentdate, "DD-MMM-YYYY"), style: 'headerData', margin: [4, 0, 0, 0],
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 70,
                                                    text: 'Loan Type',
                                                    style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: ':   ',
                                                }, {
                                                    width: 200,
                                                    text: loanData.loaninfo.loanType, style: 'headerData', margin: [4, 0, 0, 0],
                                                },
                                                {
                                                    width: 120,
                                                    text: 'Last Instalment Date', style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: ':   '
                                                },
                                                {
                                                    width: 200,
                                                    text: Helper.ToDate(loanData.loaninfo.lastinstalmentdate, "DD-MMM-YYYY"), style: 'headerData', margin: [4, 0, 0, 0],
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 70,
                                                    text: 'Tenure',
                                                    style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: ':   ',
                                                }, {
                                                    width: 200,
                                                    text: loanData.loaninfo.tenure, style: 'headerData', margin: [4, 0, 0, 0],
                                                },
                                                {
                                                    width: 120,
                                                    text: 'Current Status', style: 'headerData', bold: true,
                                                },
                                                {
                                                    width: '*',
                                                    text: ':   ', bold: true,
                                                },
                                                {
                                                    width: 200,
                                                    text: loanData.loaninfo.loanStatus, style: 'headerData', bold: true, margin: [4, 0, 0, 0],
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 70,
                                                    text: 'Interest',
                                                    style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: ':   ',
                                                }, {
                                                    width: 200,
                                                    text: loanData.loaninfo.rateOfInterest, style: 'headerData', margin: [4, 0, 0, 0],
                                                },
                                                {
                                                    width: 120,
                                                    text: 'Disburse Date', style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: ':   '
                                                },
                                                {
                                                    width: 200,
                                                    text: Helper.ToDate(loanData.loaninfo.disbursedDate, "DD-MMM-YYYY"), style: 'headerData', margin: [4, 0, 0, 0],
                                                }
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 70,
                                                    text: '',
                                                    style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: '',
                                                }, {
                                                    width: 200,
                                                    text: "", style: 'headerData',
                                                },
                                                {
                                                    width: 120,
                                                    text: 'Loan Closed Date', style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: ':   '
                                                },
                                                {
                                                    width: 200,
                                                    text: Helper.ToDate(loanData.loaninfo.dateOfClosed, "DD-MMM-YYYY"), style: 'headerData', margin: [4, 0, 0, 0],
                                                }
                                            ]
                                        },
                                    ],
                                    margin: [12, 12, 0, 12],

                                },
                            ],
                        ],
                    },
                    layout: {
                        hLineColor: function (i) {
                            return i === 0 ? 'white' : 'black'; // Set the top border color to white
                        },
                        vLineColor: function (i) {
                            return i === 0 ? 'black' : 'black';
                        },
                    },
                },
                {
                    margin: [0, 0, 0, 0],
                    table: {
                        widths: [514, '*', '*'],
                        heights: 20,
                        body: [
                            [
                                {
                                    text: 'TRANSACTION DETAILS',
                                    style: 'visit',
                                },
                            ],
                        ], layout: {
                            hLineWidth: function (i, node) {
                                // Set the bottom border width to 0 for all rows
                                return (i === node.table.body.length) ? 0 : 1;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                        },
                    },
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: [70, 60, 168, 50, 50, 78],
                        body: [
                            [
                                { text: 'Trans.Date', style: 'tableHeader' },
                                { text: 'Trans.No.', style: 'tableHeader' },
                                { text: 'Particulars', style: 'tableHeader' },
                                { text: 'Debit', style: 'tableHeader' },
                                { text: 'Credit', style: 'tableHeader' },
                                { text: 'Balance', style: 'tableHeader' },
                            ],
                        ]
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return 0; // Remove horizontal lines
                        },
                        vLineWidth: function (i, node) {
                            return 0; // Remove vertical lines
                        },
                    },
                },
                {
                    style: 'tableExample',
                    heights: 10,
                    fontSize: 10,
                    table: {
                        widths: [70, 60, 164, 50, 54, 76],
                        body: tableBody
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return 1; // Set bottom border for every row
                        },
                        vLineWidth: function (i, node) {
                            if (i === 0 || i === node.table.body.length) {
                                return (i === 0 || i === node.table.body.length - 1) ? 1 : 0;
                            } else if (i === node.table.widths.length) {
                                return 1
                            } else {
                                return 0; // Do not set vertical borders for other columns
                            }
                        },
                        hLineColor: function (i, node) {
                            return 'gray'; // Set color for the bottom border
                        },
                        vLineColor: function (i, node) {
                            return 'gray'; // Set color for vertical borders
                        },
                    },
                },
                {
                    margin: [0, 10, 0, 0],
                    table: {
                        widths: [514, '*', '*'],
                        heights: 20,
                        body: [
                            [
                                {
                                    text: 'SUMMARY',
                                    style: 'visit'
                                },
                            ],
                        ],
                        layout: {
                            hLineWidth: function (i, node) {
                                // Set the bottom border width to 0 for all rows
                                return (i === node.table.body.length) ? 0 : 1;
                            },
                            vLineWidth: function (i, node) {
                                return 0;
                            },
                        },
                    },

                },
                {
                    style: 'tableExample',
                    margin: [0, 0, 0, 10],
                    table: {
                        widths: [514, '*', '*'],
                        body: [
                            [
                                {
                                    stack: [
                                        {
                                            columns: [

                                                {
                                                    width: 250,
                                                    text: 'I)     Total Instalment Receivable',
                                                    style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: '=',
                                                }, {
                                                    width: 250,
                                                    text: Helper.INRCurrencyFormat(loanData.loaninfo.totalInstalmentReceivable), style: 'headerData', margin: [10, 0, 0, 0],
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 250,
                                                    text: 'II)    Instalment Received',
                                                    style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: '=',
                                                }, {
                                                    width: 250,
                                                    text: Helper.INRCurrencyFormat(loanData.loaninfo.instalmentReceived), style: 'headerData', margin: [10, 0, 0, 0],
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 250,
                                                    text: 'III)   Other Charges Adjusted',
                                                    style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: '=',
                                                }, {
                                                    width: 250,
                                                    text: Helper.INRCurrencyFormat(loanData.loaninfo.otherChargesAdjusted), style: 'headerData', margin: [10, 0, 0, 0],
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 250,
                                                    text: 'IV)   Instalment Due (I) - (II) + (III)',
                                                    style: 'headerData',
                                                    color: 'red',
                                                    bold: true,
                                                },
                                                {
                                                    width: '*',
                                                    text: '=',
                                                    color: 'red',
                                                    bold: true,
                                                }, {
                                                    width: 250,
                                                    text: `${Helper.INRCurrencyFormat(loanData.loaninfo.instalmentDue)} Dr`, style: 'headerData', bold: true, margin: [10, 0, 0, 0], color: 'red'
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 250,
                                                    text: 'V)    Interest Receivable',
                                                    style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: '=',
                                                }, {
                                                    width: 250,
                                                    text: Helper.INRCurrencyFormat(loanData.loaninfo.interestReceivable), style: 'headerData', margin: [10, 0, 0, 0],
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 250,
                                                    text: 'VI)   Penalty Receivable',
                                                    style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: '=',
                                                }, {
                                                    width: 250,
                                                    text: Helper.INRCurrencyFormat(loanData.loaninfo.penaltyReceived), style: 'headerData', margin: [10, 0, 0, 0],
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 250,
                                                    text: 'VII)  Penalty Received',
                                                    style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: '=',
                                                }, {
                                                    width: 250,
                                                    text: Helper.INRCurrencyFormat(loanData.loaninfo.penaltyReceived), style: 'headerData', margin: [10, 0, 0, 0],
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 250,
                                                    text: 'VIII) Penalty Waived Off',
                                                    style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: '=',
                                                }, {
                                                    width: 250,
                                                    text: Helper.INRCurrencyFormat(loanData.loaninfo.penaltyWaivedOff), style: 'headerData', margin: [10, 0, 0, 0],
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 250,
                                                    text: 'IX)   Penalty Due (VI) - ((VII) + (VII)',
                                                    style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: '=',
                                                }, {
                                                    width: 250,
                                                    text: Helper.INRCurrencyFormat(loanData.loaninfo.penaltyDue), style: 'headerData', margin: [10, 0, 0, 0],
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 250,
                                                    text: 'X)    Interest Waived Off',
                                                    style: 'headerData',
                                                },
                                                {
                                                    width: '*',
                                                    text: '=',
                                                }, {
                                                    width: 250,
                                                    text: Helper.INRCurrencyFormat(loanData.loaninfo.interestWaivedOff), style: 'headerData', margin: [10, 0, 0, 0],
                                                },
                                            ]
                                        },
                                        {
                                            columns: [
                                                {
                                                    width: 250,
                                                    text: 'XI)   Balance (IV)+(IX)-(X)',
                                                    style: 'headerData',
                                                    color: 'red',
                                                    bold: true,
                                                },
                                                {
                                                    width: '*',
                                                    text: '=',
                                                    color: 'red',
                                                    bold: true,
                                                }, {
                                                    width: 250,
                                                    text: `${Helper.INRCurrencyFormat(loanData.loaninfo.balance)} Dr`, style: 'headerData', color: 'red', bold: true, margin: [10, 0, 0, 0],
                                                },
                                            ]
                                        },
                                    ],
                                    margin: [12, 12, 0, 12],

                                },
                            ],
                        ],
                    },
                    layout: {
                        hLineColor: function (i) {
                            return i === 0 ? 'white' : 'black'; // Set the top border color to white
                        },
                        vLineColor: function (i) {
                            return i === 0 ? 'black' : 'black';
                        },
                    },
                },
            ],
            styles: {
                header: {
                    fontSize: 10,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 0, 0, 2],
                },
                subheader: {
                    fontSize: 8,
                    alignment: 'center',
                    margin: [0, 0, 0, 2],
                },
                headerData: {
                    fontSize: 10,
                    alignment: 'left',
                    margin: [0, 0, 0, 2],
                },
                summury: {
                    fontSize: 11,
                    margin: [0, 0, 0, 6]
                },
                visit: {
                    bold: true,
                    fillColor: '#043D54',
                    color: "#ffffff",
                    margin: [4, 4, 6, 0]
                },
                tableHeader: {
                    fillColor: '#176fa4',
                    color: "#ffffff",
                    heights: 10,
                }
            },
        };
        const pdfDoc = pdfmake.createPdf(docDefinition);
        return pdfDoc
    } catch (error) {
        console.log(error);
    }
};
export { pdfGenerator }