import { React, Container, Link } from "common";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_about">
                            <div className="inPgBcShadeBlk">
                                <div className="inPgBcCrossBlk">
                                    <div className="widthfix_10px">
                                        <ul className="bc_IIcol">
                                            <li className="bc_IIcol">
                                                <div className="inPgBcHalfBlk">
                                                    <div className="inPgBcTextBlk">
                                                        <Link to="/"><div className="bcMainTxt">Home</div></Link>
                                                        <div className="bcSubTxt">/ BL &amp; PL Loan Charges</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="bc_IIcol">
                                                <ul className="bcPointsSlanting">
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Strong network of our branches</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">More than 5 lakh satisfied customers</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Diversified loan options for every need</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="charges">
                            <div className="widthfix_10px">
                                <ul className="aboutIIcol">
                                    <li className="aboutIIcol">
                                        <ul className="aboutLeftNavi">
                                            <li className="aboutLeftNavi">
                                                <Link to="/chargesbusiness" className="current">BL &amp; PL Charges</Link>
                                            </li>
                                            <li className="aboutLeftNavi">
                                                <Link to="/chargesbike">Two Wheeler Loan Charges</Link>
                                            </li>
                                            <li className="aboutLeftNavi">
                                                <Link to="/chargesgold">Gold Loan Charges</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="aboutIIcol">
                                        <ul className="aboutIISubCol">
                                            <li className="aboutIISubCol">
                                                <div className="aboutHeadText">Business &amp; Personal Loan Charges</div>

                                                <table className="chargesTable">
                                                    <thead>
                                                        <tr>
                                                            <th>Unsecured Business Loan &amp; Personal Loan</th>
                                                            <th>Schedule of Charges (Inclusive of GST)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Bounce Charges:</td>
                                                            <td>Rs. 600</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Cash Collection / Visit Charges:</td>
                                                            <td>Rs. 100 per visit</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ACH Failure Charges: </td>
                                                            <td>Rs. 500</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Copy of Loan Agreement: </td>
                                                            <td>Rs. 100</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Duplicate NDC/NOC Charges: </td>
                                                            <td>Rs. 500</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Duplicate Repayment Schedule - Hard Copy: </td>
                                                            <td>Rs. 100</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Foreclosure Charges: </td>
                                                            <td>4% of the future principal outstanding</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Foreclosure Statement Charges - Hard Copy: </td>
                                                            <td>Rs. 100</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Insurance Related : At Actuals</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td> Legal Verification Charges (If Applicable): </td>
                                                            <td>At Actuals</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Legal, Repossession & Incidental Charges: </td>
                                                            <td>At Actuals</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Loan Cancellation Charges:</td>
                                                            <td> 0.5% of loan amount or Rs. 5000, whichever is higher</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Loan Restructuring Charges: </td>
                                                            <td>0.50% of loan outstanding</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Mandate Rejection Charges: </td>
                                                            <td>Rs. 500</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Penality Charges: </td>
                                                            <td>24% per annum on unpaid installment</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Processing Fees: </td>
                                                            <td>Up to 3.5%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Stamp Duty/Stamping/Franking Charges: </td>
                                                            <td>At Actuals</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Statement of Accounts - Hard Copy: </td>
                                                            <td>Rs. 100</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Swap Charges: </td>
                                                            <td>Rs. 500</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Valuation Charges (If Applicable): </td>
                                                            <td>At Actuals</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </li>
                                            <li className="aboutIISubCol">
                                                <div className="aboutContactBlk">
                                                    <div className="abtContactIcon"></div>
                                                    <div className="abtContactTxtHead">Talk to Sales</div>
                                                    <div className="abtContactTxt">Want to discuss about a loan? </div>
                                                    <div className="abtContactTxt">Just pick up the phone to chat with a member of our sales team.</div>
                                                    <div className="abtContactPhNo">+91 80 26496555</div>
                                                </div>
                                            </li>
                                        </ul>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
