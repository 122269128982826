import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut as DoughnutChart } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);
import { Helper } from "helper";

const Component = (props) => {
    const { chartText, values } = props;

    const drawCenterText = (props) => {
        const { chartText } = props.config.options;
        if (!Helper.IsNullValue(chartText)) {
            const { width, height, ctx } = props;
            ctx.restore();
            ctx.font = "1em Agaramond_b";
            ctx.textBaseline = "middle";

            var lines = chartText.split('\n');

            if (lines.length > 1) {
                var lineheight = 20;
                var extraPix = (lines.length + 1) * 6;
                var textY = (height / (lines.length + 1)) + extraPix;
                for (var i = 0; i < lines.length; i++) {
                    var maxText = lines[i].trim();
                    var textX = Math.round((width - ctx.measureText(maxText).width) / 2);
                    if (i === 1) {
                        ctx.font = "0.8em Agaramond_b";
                        textX = Math.round((width - ctx.measureText(maxText).width) / 2);
                    }
                    ctx.fillText(maxText, textX, textY + (i * lineheight));
                }
            } else {
                var textY = (height / 2) + 5;
                var textX = Math.round((width - ctx.measureText(chartText).width) / 2);
                ctx.fillText(chartText, textX, textY);
            }

            ctx.save();
        }
    }

    return (
        <DoughnutChart
            options={{ chartText, plugins: { tooltip: { enabled: false } } }}
            plugins={[{ beforeDraw(chart) { drawCenterText(chart) } }]}
            data={{
                labels: [],
                datasets: [
                    {
                        data: values,
                        backgroundColor: ['#c21200', '#004466'],
                        borderColor: ['#d9e3ed', '#d9e3ed'],
                        borderWidth: 0,
                    },
                ]
            }} />
    );

};

export default Component;