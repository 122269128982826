import { QuickApply } from "../components";

const Component = (props) => {

    const { show, onClose } = props;

    if (!show) return null;

    return (
        <div id="easyApply_pop" className="pop_disable_bg" style={{ display: "block" }}>
            <div className="easyApply_pop">
                <div className="easyApply_ContentArea">
                    <QuickApply onClose={onClose} />
                </div>
            </div>
        </div >
    );
};

export default Component;