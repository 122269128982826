import { React, Support, Helper, DropDown, TextInput } from "common";

const CobrLocatedDetails = (props) => {

    const { row, errors, onInputChanged, onBlurError, onInputClicked } = props;

    let rootpath = 'coborrowerlocationdetails';

    return (
        <>
            <div className="applyHead_borderBottom">
                Co-Borrower Address
            </div>
            <ul className="contentIIIcol">
                <li className="contentIIIcol">
                    <div className="applyContentHead">Occupant Status</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.occupantStatus`}
                            value={row?.occupantStatus}
                            onInputChildChanged={onInputChanged}
                            items={Support.OccupantTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Occupant Status is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Address Line 1</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        {/* <GeoLocation name={`${path}.addressLine1`}> */}
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.addressLine1`}
                            value={row?.addressLine1}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'Address Line 1 is required'}
                            dataRequired={true}
                            required={true}
                        />
                        {/* </GeoLocation> */}
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Address Line 2</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.addressLine2`}
                            value={row?.addressLine2}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            dataRequired={false}
                            required={false}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Pin Code</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.zipcode`}
                            value={row?.zipcode}
                            valuetype={"ZIP"}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'Pin Code is required'}
                            validationMessage={'Enter valid Pin Code'}
                            dataRequired={true}
                            required={true}
                            validate={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">State</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.state`}
                            value={row?.state}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={"State is required"}
                            dataRequired={true}
                            required={true}
                            readOnly={Helper.ToBool(row?.statereadonly)}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">City / Place</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.city`}
                            value={row?.city}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={"City is required"}
                            dataRequired={true}
                            required={true}
                            readOnly={Helper.ToBool(row?.cityreadonly)}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">District</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.district`}
                            value={row?.district}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={"City is required"}
                            dataRequired={true}
                            required={true}
                            readOnly={Helper.ToBool(row?.districtreadonly)}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Time at Residence</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.residenceTime`}
                            value={row?.residenceTime}
                            onInputChildChanged={onInputChanged}
                            items={Support.ResidenceTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Time at Residence is required"}
                        />
                    </div>
                </li>
            </ul>
            <div className="spacer_20margin_bottom"></div>
        </>
    );

}

export default CobrLocatedDetails;