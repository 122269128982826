import { React, Link, Container, useTimerSession } from "common";

const Component = () => {

    const [token] = useTimerSession('token');
    const contentId = token ? 'content' : 'content_fullHeader';

    const handleLanguageChange = (e) => {
        const selectedLanguage = e.target.value;
        if (selectedLanguage !== "DEFAULT") {
            const link = document.createElement('a');
            link.href = `/content/document/${selectedLanguage}.pdf`; // Ensure the path matches where your PDFs are stored
            link.download = `${selectedLanguage}.pdf`;
            link.click();
        }
    };

    return (
        <>
            <Container>
                <div id="container" >
                    <div id={contentId}>
                        {!token && (
                            <div id="heroImg_about">
                                <div className="inPgBcShadeBlk">
                                    <div className="inPgBcCrossBlk">
                                        <div className="widthfix_10px">
                                            <ul className="bc_IIcol">
                                                <li className="bc_IIcol">
                                                    <div className="inPgBcHalfBlk">
                                                        <div className="inPgBcTextBlk">
                                                            <Link to="/"><div className="bcMainTxt">Home</div></Link>
                                                            <div className="bcSubTxt">/ Fair Practice Code</div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="bc_IIcol">
                                                    <ul className="bcPointsSlanting">
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Builds trust with customers</li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Ensure compliance with all applicable laws</li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Lead to higher level of customer satisfaction</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="fpc">
                            <div className="widthfix_10px">
                                <div className="inPgHeadTxtNoMargin">POLICY GUIDELINES ON FAIR PRACTICES CODE ADOPTED BY</div>
                                <div className="inPgHeadTxtNoMargin">MOHAM CAPITAL PRIVATE LIMITED</div>
                                <div className="fpcDownload">
                                    <div className="fpcTxtHead">Download in preferred language</div>

                                    <select
                                        name="filterType"
                                        defaultValue="DEFAULT"
                                        onChange={handleLanguageChange}
                                        required
                                    >
                                        <option value="DEFAULT">Select</option>
                                        <option value="MCPL_FPC_English">English</option>
                                        <option value="MCPL_FPC_Hindi">Hindi</option>
                                        <option value="MCPL_FPC_Kannada">Kannada</option>
                                        <option value="MCPL_FPC_Tamil">Tamil</option>
                                    </select>
                                </div>
                                <div className="pgTxtBold">Preamble</div>
                                <div className="pgTxtNormal">The Reserve Bank of India (RBI) has issued guidelines on Fair Practices Code for Non-Banking Finance Companies (NBFCs) thereby setting standards for fair business and corporate practices while dealing with their customers (Circular No. RBI/2006-07/138 DNBS (PD) CC No. 80 / 03.10.042/ 2005-06).</div>
                                <div className="pgTxtNormal">The Company shall adopt all the best practices prescribed by RBI from time to time and shall make appropriate modifications if any necessary to this Code to conform to the standards so prescribed.</div>
                                <div className="pgTxtNormal">Under RBI vide Master Circular DNBS (PD) CC No.388/ 03.10.042 / 2014-15 dated July 1, 2014, had amended guidelines on Fair Practices Code for NBFCs and accordingly suitable modifications are made hereunder in the Code to comply with the aforesaid guidelines.</div>
                                <div className="pgTxtNormal">The Fair Practices Code, as adopted herein below, conforms with the Guidelines on Fair Practices Code for NBFCs as contained in the aforesaid RBI Circular(s).</div>
                                <div className="pgTxtBold">Introduction</div>
                                <div className="pgTxtNormal">It shall be the policy of Moham Capital Private Limited (“MCPL”) to make available to all eligible qualified applicants, without discrimination based on race, caste, color, religion, sex, marital status, age, or handicap all financial products, either directly or through subsidiaries and/or associates.</div>
                                <div className="pgTxtNormal">The Company’s policy is to treat all the clients consistently and fairly. The employees of the Company will offer assistance, encouragement, and service in a fair, equitable, and consistent manner. The Company will also communicate its Fair Practices Code (FPC) to its customers by having this FPC hosted on its website.</div>
                                <div className="pgTxtNormal">The Company will ensure that the implementation of the FPC is the responsibility of the entire organization. The Company’s fair lending practices shall apply across all aspects of its operations including marketing, loan origination, processing servicing, and collection/recovery activities. Its commitment to FPC will be demonstrated in terms of employee accountability, training, counseling, and monitoring, auditing programs and internal controls, and optimal use of technology.</div>
                                <div className="pgTxtNormal">The Company’s Board of Directors and the management team are responsible for implementing the fair practices hereinafter detailed, and also to ensure that its operations reflect its strong commitment to all the stakeholders for offering fairly and equitably, the various financial services and products including financing. It shall be ensured that all the stakeholders of the company including the employees are aware of this commitment.</div>
                                <div className="pgTxtNormal">This Fair Practices Code applies to the following categories of products and services offered by us (currently planning to offer or which will be introduced at a future date).</div>
                                <ul className="bulletPoints_un">
                                    <div className="bp_indent_30">
                                        <li className="bp_circleOutline">Loans, guarantees, and other products like financial assets.</li>
                                        <li className="bp_circleOutline">Products offered through a network consisting of the branches of the Company, its subsidiaries, and associates.</li>
                                    </div>
                                </ul>
                                <div className="pgTxtNormal">The FPC applies to the above irrespective of whether the same is provided physically, over the phone, on the internet, or by any other method whatsoever, existing or futuristic.</div>
                                <div className="pgTxtBold">Declarations &amp; Commitments</div>
                                <div className="pgTxtNormal">The Company undertakes to abide by all applicable laws, regulations, and guidelines passed/issued by the Regulators (Reserve Bank of India, SEBI, IRDA, etc.) and other competent authorities such as Government, Local Authority, etc.</div>
                                <div className="pgTxtNormal">The key commitments which the Company promises to follow in its dealings with its customers are:</div>
                                <ul className="bulletPoints_un">
                                    <div className="bp_indent_30">
                                        <li className="bp_circleOutline">To act fairly and reasonably in all dealings with its customers by ensuring that:</li>
                                        <div className="bp_indent_30">
                                            <li className="bp_circle">Its products, services, procedures, and practices will meet the commitments and standards in this FPC.</li>
                                            <li className="bp_circle">Its products and services will meet relevant laws and regulations in letter and spirit as.</li>
                                            <li className="bp_circle">Its dealings with its customers will rest on ethical principles of honesty, integrity, and transparency.</li>
                                        </div>
                                        <li className="bp_circleOutline">The Company will assist customers in understanding how its financial products and services work by:</li>
                                        <div className="bp_indent_30">
                                            <li className="bp_circle">Providing information about them in simple Hindi and/or English and/or the local/vernacular language.</li>
                                            <li className="bp_circle">Explaining their financial implications and</li>
                                            <li className="bp_circle">Helping the customer choose the one that meets his / her needs.</li>
                                        </div>
                                        <li className="bp_circleOutline">The Company will make every attempt to ensure that its customers have a trouble-free experience in dealing with it; but in the case of errors or commissions and omissions, the Company will deal with the same quickly and sympathetically.</li>
                                        <div className="bp_indent_30">
                                            <li className="bp_circle">Mistakes will be corrected quickly.</li>
                                            <li className="bp_circle">Complaints will be handled quickly.</li>
                                            <li className="bp_circle">In case a customer is not satisfied with the way a complaint is handled, the Company will guide the customer on how to take the complaint forward.</li>
                                            <li className="bp_circle">The Company will reverse any charges including interest applied to a customer's account due to an error or oversight on its part.</li>
                                        </div>
                                    </div>
                                </ul>
                                <div className="pgTxtBold">Sales &amp; Marketing</div>
                                <div className="pgTxtNormal">The Company will guide its customers in choosing products and services which meet his / her requirements.</div>
                                <ul className="bulletPoints_un">
                                    <div className="bp_indent_30">
                                        <li className="bp_circleOutline">The Company shall not deliberately promote a product with any ulterior/selfish motives or contrary to the customer requirements or expectations as disclosed by the customer. The Company will ensure that its personnel engaged in marketing and operations are suitably trained and instructed to preclude selling of its products by misrepresentation to the customer / prospective customer.</li>
                                        <li className="bp_circleOutline">The Company will not indulge in profiteering by charging usurious rates of interest on loans or take undue advantage of adverse market conditions. The rates of interest will be based on variables such as cost of funds, risk premium, loan scheme, profit margin, etc. and shall conform with the Interest Rate Policy of the Company and Regulatory Guidelines from time to time. It shall also, by and large, be in tune with industry practices and benchmarks.</li>
                                        <li className="bp_circleOutline">Full and updated information regarding loan schemes, rate of interest, charges, etc. will be displayed on the website of the Company and also displayed in the branches. Complete or select information will also be made available through various media channels, posters, brochures, notices, displays, etc. based on the decisions of the management of the Company from time to time.</li>
                                        <li className="bp_circleOutline">The Company will provide the customers with a full range of financial products the customer is eligible for. Some of these products/services will be their own; some others will be the products of the group or associate companies and also companies with which the Company will have arrangements.</li>
                                        <li className="bp_circleOutline">The Company will endeavor to bring synergy between the various financial services, financial products, by cross-selling these products to its clientele.</li>
                                        <li className="bp_circleOutline">Before the customer relationship is established, the Company will:</li>
                                        <div className="bp_indent_30">
                                            <li className="bp_circle">Give the customer information explaining the key features of the services and products the customer has shown interest in.</li>
                                            <li className="bp_circle">Give information on accounts, products, and services which will suit the customer’s needs.</li>
                                            <li className="bp_circle">Clearly state the information that the Company requires to collect from the customer to fulfill its ‘Know Your Customer’ norms and to comply with legal and regulatory requirements in force from time to time.</li>
                                            <li className="bp_circle">Request for additional information about the customer and his / her family to build a database; but this information will be furnished by the customer only if she/he wishes to do so.</li>
                                        </div>
                                        <li className="bp_circleOutline">The Company will provide information on the various channels that can be used to access its products and services. The customer will also be informed on where further information on this is available.</li>
                                        <li className="bp_circleOutline">Once a customer has chosen a product, the Company will tell the customer how it works.</li>
                                        <li className="bp_circleOutline">The Company will guide its customer on rights and responsibilities specific to the mode of operation under which the product is taken.</li>
                                    </div>
                                </ul>
                                <div className="pgTxtBold">Loans and Advances</div>
                                <div className="pgTxtNormal">Before offering any kind of fund based or non-fund based non-banking facility, the Company will assess the ability of the customer (prospective borrower) to repay.</div>
                                <div className="pgTxtBold">Application for loans and their processing</div>
                                <ul className="bulletPoints_un">
                                    <div className="bp_indent_30">
                                        <li className="bp_circleOutline">Loan application forms of the Company will include the necessary information that is likely to affect the interests of the prospective borrower so that a meaningful comparison with the terms and conditions offered by other Non- Banking Financial Companies can be made and an informed decision taken by the prospective borrower.</li>
                                        <li className="bp_circleOutline">The loan application form shall indicate the documents required to be submitted along with the application form.</li>
                                        <li className="bp_circleOutline">The Company shall provide to the prospective borrower an acknowledgment for receipt of all loan applications. An indicative time frame within which loan applications will be disposed of will be mentioned in such acknowledgment.</li>
                                        <li className="bp_circleOutline">All communications to the borrower shall be in the vernacular language or a language as understood by the borrower.</li>
                                    </div>
                                </ul>
                                <div className="pgTxtBold">Loan Appraisal &amp; terms / conditions</div>
                                <div className="pgTxtNormal">MCPL shall convey in writing to the borrower in the vernacular language as understood by the borrower using sanction letter or otherwise, the amount of loan sanctioned along with the terms and conditions including annualized rate of interest and method of application thereof and keep the acceptance of these terms and conditions by the borrower on its record. As complaints received against NBFCs generally pertain to the charging of high interest / penal interest, MCPL shall mention the penal interest charged for late repayment in bold in the loan agreement.</div>
                                <div className="pgTxtNormal">Appropriate internal principles and procedures for determining interest and other charges shall be laid down and be subjected to review keeping in view the business exigencies, regulatory and customer sentiments, market practices, etc. The acceptance of the terms and conditions communicated by the borrower shall be preserved by the Company in its records.</div>
                                <div className="pgTxtNormal">MCPL shall furnish a copy of the loan agreement preferably in the vernacular language as understood by the borrower along with a copy of each of all enclosures quoted in the loan agreement to all the borrowers at the time of sanction/disbursement of loans.</div>
                                <div className="pgTxtBold">Disbursement of loans including changes in terms & conditions</div>
                                <ul className="bulletPoints_un">
                                    <div className="bp_indent_30">
                                        <li className="bp_circleOutline">The Company shall give notice to the borrower in the vernacular language as understood by the borrower of any change in the terms and conditions including disbursement schedule, interest rates, service charges, prepayment charges, etc. The Company shall ensure that changes in interest rates and other charges are effected only prospectively. The loan agreement shall contain a specific clause to this effect.</li>
                                        <li className="bp_circleOutline">The decision of the Company to recall/accelerate payment or performance shall agree with the terms of the loan agreement.</li>
                                        <li className="bp_circleOutline">The Company shall release all securities upon repayment of all dues or on the realization of the outstanding amount of loan subject to any legitimate right or lien for any other claim it will have against the borrower. In case such right of set-off is to be exercised, the borrower shall be given due notice with full particulars about the outstanding claims and the conditions under which the Company is entitled to retain the securities till the relevant claim is settled/paid.</li>
                                    </div>
                                </ul>
                                <div className="pgTxtBold">General</div>
                                <ul className="bulletPoints_un">
                                    <div className="bp_indent_30">
                                        <li className="bp_circleOutline">MCPL shall refrain from interfering in the affairs of the borrower except for the purposes provided in the terms and conditions of the loan agreement, unless new information, not earlier disclosed deliberately or otherwise by the borrower, has come to its notice.</li>
                                        <li className="bp_circleOutline">In case of receipt of a request from the borrower for transfer of a borrower account to other NBFC, Bank of Financial Institution, the consent or otherwise shall be conveyed within 21 days from the date of receipt of such request. Such transfer shall be by the contractual terms entered into with the borrower and in consonance with the statutes, rules, regulations, and guidelines as will be applicable from time to time.</li>
                                        <li className="bp_circleOutline">In the matter of recovery of loans, the Company shall resort only to remedies which are legally and legitimately available to it and will avoid using recovery measures during odd hours of the day, undue harassment, use of muscle power for recovery of loans. As complaints from customers also include rude behavior from the staff of the companies. MCPL shall ensure that the staff is adequately trained to deal with the customers appropriately.</li>
                                        <li className="bp_circleOutline">There shall be no discrimination in extending products and facilities including loan facilities to the physically / visually challenged applicants on grounds of disability (Ref: RBI Circular No. DNBS.CC.PD.No. 191/03.10.01/2010-11 dated July 27, 2010).</li>
                                    </div>
                                </ul>
                                <div className="pgTxtBold">Terms and Conditions</div>
                                <ul className="bulletPoints_un">
                                    <div className="bp_indent_30">
                                        <li className="bp_circleOutline">When a customer accepts a product or service for the first time, the Company will provide the customer with the Rules and Regulations that are relevant to the product/service.</li>
                                        <li className="bp_circleOutline">All Terms and Conditions will be fair and will set the customers rights and responsibilities clearly and in plain language, the Company will use legal or technical language only where necessary.</li>
                                        <li className="bp_circleOutline">The Company will make available any charges applicable to products and services applicable</li>
                                        <li className="bp_circleOutline">If the Company increases any charges or introduces a new charge. The Company will notify the same to the customers.</li>
                                        <li className="bp_circleOutline">The Company will provide the terms and conditions in respect of any product or service whenever a customer requests for the same.</li>
                                    </div>
                                </ul>
                                <div className="pgTxtBold">Confidentiality</div>
                                <ul className="bulletPoints_un">
                                    <div className="bp_indent_30">
                                        <li className="bp_circleOutline">Unless authorized by the customer the Company will treat all personal information as private and confidential.</li>
                                        <li className="bp_circleOutline">The Company will not reveal transaction details to any other entity including within the group other than the following exceptional cases.</li>
                                        <div className="bp_indent_30">
                                            <li className="bp_circle">If the Company has to provide the information by statutory or regulatory laws.</li>
                                            <li className="bp_circle">If there is a duty to the public to reveal this information.</li>
                                            <li className="bp_circle">If its interest requires us to provide this information (e.g. fraud prevention).</li>
                                        </div>
                                        <li className="bp_circleOutline">The Company will not use this reason for giving information about customers to anyone else (including group companies) for marketing purposes. </li>
                                        <div className="bp_indent_30">
                                            <li className="bp_circle">If the customer has given consent/concurrence to provide/ share such information to its group/associate/entities or companies for providing other products or services.</li>
                                            <li className="bp_circle">Where the customer asks us to reveal such information to its group/associate/entities or companies for providing other services or products.</li>
                                        </div>
                                    </div>
                                </ul>
                                <div className="pgTxtBold">Grievance Redressal Mechanism</div>
                                <div className="pgTxtNormal">The Company shall also lay down the appropriate grievance redressal mechanism within the organization to resolve disputes arising in this regard. Such a mechanism shall ensure that all disputes arising out of the decisions of the Company’s functionaries are heard and disposed of at least at the next higher level. The Board of Directors shall also provide for periodical review of the compliance of the Fair Practices Code and the functioning of the grievance’s redressal mechanism at various levels of management. A consolidated report of such reviews shall be submitted to the Board at regular intervals, as may be prescribed by it. To start with Company may provide the same quarterly.</div>
                                <ul className="bulletPoints_un">
                                    <div className="bp_indent_30">
                                        <li className="bp_circleOutline">The Company will guide customers who wish to complain and also provide guidance on what to do in case the customer is unhappy with the outcome.</li>
                                        <li className="bp_circleOutline">After examining the matter, the Company will send a response as soon as possible; the Company will also guide a customer on how to take the complaint further if the customer is not satisfied.</li>
                                        <li className="bp_circleOutline">A nodal officer shall be appointed for the redressal of grievances of the customers including the borrowers, in connection with any matter about business practices, lending decisions, credit management, and recovery. The name and contact details of the nodal officer shall be displayed on the website of the Company.</li>
                                        <li className="bp_circleOutline">Internally constituted Customer Grievance Redressal Council comprising senior management officials, to review with the Nodal Officer and oversee the Grievance Redressal Mechanism.</li>
                                        <li className="bp_circleOutline">As per RBI Circular No. RBI/2012-13/416 DNBS.CC.PD.No.320/03.10.01/2012-13, Dated February 18, 2013, the Company shall display the following information prominently, for the benefit of the customers, at their branches/places where the business is transacted:</li>
                                    </div>
                                </ul>
                                <div className="pgTxtNormal">In short, the public notice shall serve the purpose of highlighting to the customers, the grievance redressal mechanism followed by the company, together with details of the grievance redressal officer and the Regional Office of the RBI.</div>
                                <div className="pgTxtBold">Repossession of vehicles/property</div>
                                <div className="pgTxtNormal">The Company shall have a built-in re-possession clause in the contract/loan agreement with the borrower which shall be legally enforceable. To ensure transparency, the terms and conditions of the contract/loan agreement shall also contain provisions regarding:</div>
                                <ul className="bulletPoints_un">
                                    <div className="bp_indent_30">
                                        <li className="bp_circleOutline">Notice period before taking possession;</li>
                                        <li className="bp_circleOutline">Circumstances under which the notice period can be waived;</li>
                                        <li className="bp_circleOutline">The procedure for taking possession of the security;</li>
                                        <li className="bp_circleOutline">A provision regarding the final chance to be given to the borrower for repayment of the loan before the sale/auction of the property;</li>
                                        <li className="bp_circleOutline">The procedure for giving repossession to the borrower; and</li>
                                        <li className="bp_circleOutline">The procedure for sale/auction of the property.</li>
                                    </div>
                                </ul>
                                <div className="pgTxtBold">Regulation of Excessive Interest Rate</div>
                                <ul className="bulletPoints_un">
                                    <div className="bp_indent_30">
                                        <li className="bp_circleOutline">MCPL shall adopt an interest rate model taking into account relevant factors such as cost of funds, margin and risk premium, etc, and determine the rate of interest to be charged for loans and advances. The rate of interest and the approach for gradations of risk and rationale for charging different rates of interest to different categories of borrowers shall be disclosed to the borrower or customer in the application form and communicated explicitly in the sanction letter.</li>
                                        <li className="bp_circleOutline">The rates of interest and the approach for gradation of risks shall also be made available on the website of the companies or published in the relevant newspapers. The information published on the website or otherwise published should be updated whenever there is a change in the rates of interest.</li>
                                        <li className="bp_circleOutline">The rate of interest should be annualized rates so that the borrower is aware of the exact rates that would be charged to the account.</li>
                                    </div>
                                </ul>
                                <div className="pgTxtNormal">As per the circular issued on RBI/ 2014-15/12/DNBS (PD).CC.No.399/03.10.42/2014-15 Dated July 14, 2014, the Company shall not charge foreclosure charges / pre-payment penalties on all floating rate term loans sanction to Individual Borrower.</div>
                                <div className="pgTxtBold">Miscellaneous</div>
                                <ul className="bulletPoints_un">
                                    <div className="bp_indent_30">
                                        <li className="bp_circleOutline">The Company shall display the normal business hours at the respective branches, the list of holidays, and notify the changes, if any, by way of a notice displayed in the premises of the branch or through press notification.</li>
                                        <li className="bp_circleOutline">Personal information of the customer will not be shared with unauthorized persons or agencies or third parties by the Company. However, the Company will be bound to honor and comply with legal or regulatory requirements, if any, in this matter obligating it to part with such information even without notice to the customer.</li>
                                        <li className="bp_circleOutline">Wherever locker facility is extended by the Company through its branches, it shall be ensured that appropriate disclosure is in place to communicate to the customers that the activity is not regulated by the Reserve Bank.</li>
                                    </div>
                                </ul>
                                <div className="pgTxtNormal">The Company will abide by all guidelines, directives, instructions, and advice of the Reserve Bank of India as will be in force from time to time.  The contents in this document shall be read in conjunction with these guidelines, directives, instructions, and advice.  The Company will apply better practice so long as such practice does not conflict with or violate Reserve Bank of India regulations.</div>
                                <div className="pgTxtBold">For Moham Capital Private Limited</div>
                                <div className="pgTxtNormal"><b>Sujay Kanth</b><br />Director, DIN: 01722340</div>

                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
