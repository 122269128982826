const CustomButton = (props) => {
    const { css, value, onClick } = props;

    const OnClicked = (e) => {
        e.preventDefault();
        if (onClick) onClick(e);
    };

    return (
        <input type="button" className={css} value={value} onClick={OnClicked} />
    );
};

export default CustomButton;