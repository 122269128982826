var fn = {};

/* Session Start */
fn.Store = (key, value) => {
    sessionStorage.setItem(key, value);
};

fn.Retrieve = (key) => {
    return sessionStorage.getItem(key);
};

fn.StoreAsync = async (key, value) => {
    return new Promise(async (resolve) => {
        sessionStorage.setItem(key, value);
        return resolve(true);
    });
};

fn.RetrieveAsync = async (key) => {
    return new Promise(async (resolve) => {
        return resolve(sessionStorage.getItem(key));
    });
};

fn.StoreJSON = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

fn.RetrieveJSON = (key) => {
    return JSON.parse(sessionStorage.getItem(key));
};

fn.StoreJSONAsync = async (key, value) => {
    return new Promise(async (resolve) => {
        sessionStorage.setItem(key, JSON.stringify(value));
        return resolve(true);
    });
};

fn.RetrieveJSONAsync = async (key) => {
    return new Promise(async (resolve) => {
        return resolve(JSON.parse(sessionStorage.getItem(key)));
    });
};

fn.Remove = (key) => {
    return sessionStorage.removeItem(key);
};

fn.RemoveAsync = async (key) => {
    return new Promise(async (resolve) => {
        return resolve(sessionStorage.removeItem(key));
    });
};

/* Session Ends */

fn.GetHeader = async () => {
    const token = await fn.RetrieveAsync("token");
    return {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
    };
};

export default fn;
