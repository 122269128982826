import {
    React, Link, Container, Helper, useNavigate, Session
} from "common";

import { FinstaApi } from "services";

const Component = () => {
    const [initlized, setInitlized] = React.useState(false);
    const [loanAccounts, setLoanAccounts] = React.useState([]);

    const NavigateTo = useNavigate();

    const FetchResults = async () => {
        global.Busy(true);
        const statusId = 4;
        let rslt = await FinstaApi.GetLoanAccountSummary(statusId);
        global.Busy(false);
        if (rslt.status === 100) {
            setLoanAccounts(rslt.data);
        }
    };

    if (initlized) {
        setInitlized(false);
        setLoanAccounts([]);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const OnLoanViewClicked = (e, id) => {
        e.preventDefault();
        Session.Store('loanid', id);
        NavigateTo("/cld", {
            state: { isClosed: true }
        });
    }


    return (
        <>
            <Container>
                <div id="container">
                    <div id="content">
                        <ul className="bc_inPage">
                            <div className="widthfix_10px">
                                <li className="bc_inPage"><Link to="/cd">Dashboard</Link></li>
                                <li className="bc_inPage">Closed Loans</li>
                            </div>
                        </ul>
                        <div className="widthfix_10px">
                            <div className="loanListDetails">
                                <div className="loanlistContentBlk">
                                    <div className="loanlistHeadBlk">
                                        <div className="blkName">Closed Loans</div>
                                    </div>
                                    <div className="contentarea_table">
                                        <div className="closedLoanTable_head">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Loan ID</td>
                                                        <td>Loan Type</td>
                                                        <td>Opening Date</td>
                                                        <td>Maturity Date</td>
                                                        <td>Loan Amount</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="closedLoanTable">
                                            <table>
                                                <tbody>
                                                    {loanAccounts && loanAccounts.length > 0 &&
                                                        loanAccounts.map((x, index) => (
                                                            <tr key={index}>
                                                                <td>{x.loanNumber}</td>
                                                                {/* <td><Link to="#" onClick={(e) => OnLoanViewClicked(e, x._id)}>{x.loanNumber}</Link></td> */}
                                                                <td>{x.loanType}</td>
                                                                <td>{Helper.ToDate(x.startDate, "YYYY-MM-DD")}</td>
                                                                <td>{Helper.ToDate(x.maturityDate, "YYYY-MM-DD")}</td>
                                                                <td>{Helper.INRCurrencyFormat(x?.loanAmount, true)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default Component;