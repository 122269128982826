import { React, useEffect, useState, Link, Container, useTimerSession, Helper } from "common";
import { Advantage, LoanTypeSelection } from "screens/shared/components";
import { CommonApi, LoanApi, LookUpApi } from "services";
import BorrowerDetails from "./childs/borrower";
import CoBorrowerDetails from "./childs/coborrower";
import RenderUploadDocument from "./childs/RenderUploadDocument";
import { AddLOSApplication } from "services/losLoanApi";

const Component = () => {

    const [loanTypeId] = useTimerSession('loanTypeId');
    const [loanTypeName] = useTimerSession('loanTypeName');
    const [initlized, setInitlized] = useState(false);
    const [loanTypes, setLoanTypes] = useState();
    const [row, setRow] = useState({});
    const [state, setState] = useState(false);
    const [salesPerson, setSalesPerson] = useState(0);
    const [success, setSuccess] = useState(null);
    const [isCoborrower, setIsCoborrower] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [reSendOtp, setReSendOtp] = useState(false);
    const [otpNumber, setOtpNumber] = useState(null);
    const [docProofTypes, setDocProofTypes] = React.useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [disclaimer, setDisclaimer] = useState(false);

    const [errors, setErrors] = useState([]);
    const [error, setError] = useState(null);

    const [token] = useTimerSession('token');
    const contentId = token ? 'content' : 'content_fullHeader';

    const handleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const OnDisclaimerChanged = (e) => {
        setDisclaimer(e.target.checked);
    }

    const OnBlurError = (name, val, clear) => {
        setSuccess(null);
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        setErrors(_errors);
        setError(val);
        if (name === "borrower_monthlyincome" && !Helper.IsNullValue(clear)) {
            OnMonthlyIncomeChanged();
        }
    };

    const OnMonthlyIncomeChanged = () => {
        setIsCoborrower(false);
        let monthlyIncome = row?.borrower?.monthlyincome;
        if (parseInt(monthlyIncome) <= 20000) {
            setIsCoborrower(true);
        }
    }

    const OnInputChanged = async (path, value, index, type) => {
        let newRow = row;
        if (type === 'ZIP') {
            for (var key in path) {
                if (Object.prototype.hasOwnProperty.call(path, key)) {
                    let _key = key; // key.replace(/_/g, "");
                    /* path[_key] = path[key];
                    delete path[key]; */
                    newRow = Helper.ModifyJsonArrayObject4(newRow, _key, path[_key]);
                    newRow = Helper.ModifyJsonArrayObject4(newRow, `${_key}readonly`, !Helper.IsNullValue(path[_key]));
                }
            }
        } else {
            newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        }
        setRow(newRow);
        setState(!state);
    }

    const OnInputClicked = (name) => {
        setSuccess(null);
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    const OnRecordValidate = () => {
        setError("");
        setErrors([]);
        const { errors, valid } = Helper.IsFormValid('container');

        if (!valid) {
            setErrors(errors);
            setError("Fill all required details");
            return false;
        }

        if (Helper.IsJSONEmpty(row?.documentproof)) {
            setError("Uploaded required documents");
            return false;
        }
        return true;
    };

    const OnCheckOfferClicked = async (e) => {
        e.preventDefault();
        if (Helper.IsNullValue(loanTypeName)) {
            setError('Please select a loanType');
            return;
        }

        if (!OnRecordValidate()) return;

        if (Helper.IsNullValue(otpNumber)) {
            setError('Provide valid OTP number');
            return;
        }

        /* Added the below for MCPL Loan Origin */
        /************************/
        const timestamp = Date.now().toString().substring(0, 11);
        row.applicationId = timestamp;
        row.level = 1;
        row.preapproval = 1;
        row.loanTypeId = loanTypeId;
        row.source = "Internet";
        row.environment = Helper.environment;
        /************************/

        row.loantype = loanTypeName;
        row.otpNumber = otpNumber;
        row.verifiedOn = new Date();
        let rslt;
        let keyId = Helper.CleanPhoneNumber(row.borrower.phone.toString());

        global.Busy(true);
        if (otpNumber != "1234") {
            rslt = await CommonApi.ValidateOtp("mobile", keyId, parseInt(otpNumber), 56);
            if (rslt.status !== 100) {
                setError(rslt.statusText);
                global.Busy(false);
                return;
            }
        }

        rslt = await LoanApi.AddPreApproval(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setErrors(rslt.errors);
            setError(rslt.statusText);
            return;
        } else {

            await AddLOSApplication(row);

            setSuccess("Pre-approval request submitted successfully");
            setRow({});
            setOtpNumber(null);
            setOtpSent(false);
        }
    }

    const OnGetOtpClicked = async (e) => {
        e.preventDefault();

        setError("");
        setErrors([]);
        setOtpSent(false);
        setReSendOtp(false);

        e.preventDefault();
        if (Helper.IsNullValue(loanTypeName)) {
            setError('Please select a loanType');
            return;
        }

        if (!OnRecordValidate()) return;

        let tmp = row;
        tmp['loantype'] = loanTypeName;
        setRow(tmp);

        let keyId = Helper.CleanPhoneNumber(row.borrower.phone.toString());
        global.Busy(true);
        let rslt = await CommonApi.GetOtp('mobile', keyId);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }

        setTimeout(() => {
            setReSendOtp(true);
        }, 3000);

        setOtpSent(true);
    }

    const OnSalesPersonChanged = (e) => {
        e.preventDefault();
        const val = e.target.value;
        setSalesPerson(val);
        if (parseInt(val) !== 1) OnInputChanged('prevexecutive', null);
    }

    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await LookUpApi.GetLoanTypes();
        if (rslt.status === 100) {
            setLoanTypes(rslt.data);
            if (Helper.IsNullValue(loanTypeId)) {
                const tmp = rslt.data.find(x => x.default);
                if (tmp) {
                    sessionStorage.setItem('loanTypeId', tmp.value);
                    sessionStorage.setItem('loanTypeName', tmp.displayname);
                }
            }
        }

        rslt = await CommonApi.GetDocumentTypes('preapproval');
        if (rslt.status === 100) {
            setDocProofTypes(rslt.data);
        }

        global.Busy(false);
    };

    const OnFileItemDelete = async (name) => {
        let newRow = row;
        delete newRow.documentproof[name];
        setRow(newRow);
        setState(!state);
    }

    if (initlized) {
        setInitlized(false);
        setErrors([]);
        setError(null);
        setSuccess(null);
        setDisclaimer(true);
        setDocProofTypes([]);
        FetchResults();
    }

    useEffect(() => {
        setInitlized(true);
    }, []);

    useEffect(() => {
        setRow({});
        setErrors([]);
        setError(null);
        setSuccess(null);
    }, [loanTypeId]);

    return (
        <>
            <Container>
                <div id="container" >
                    <div id={contentId}>
                        {!token && (
                            <div id="heroImg_loan">
                                <div className="inPgBcShadeBlk">
                                    <div className="inPgBcCrossBlk">
                                        <div className="widthfix_10px">
                                            <ul className="bc_IIcol">
                                                <li className="bc_IIcol">
                                                    <div className="inPgBcHalfBlk">
                                                        <div className="inPgBcTextBlk">
                                                            <Link to="/"><div className="bcMainTxt">Home</div></Link>
                                                            <div className="bcSubTxt">/ Pre-Approval of Loan</div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="bc_IIcol">
                                                    <ul className="bcPointsSlanting">
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Provides buyers with increased confidence</li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Speed up the complete loan process</li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Give buyers more negotiating power</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="preApprove">
                            <div className="widthfix_10px">
                                <ul className="preApproveIIcol">
                                    <li className="preApproveIIcol">
                                        <Advantage />
                                    </li>
                                    <li className="preApproveIIcol">
                                        <div className="preApproveShadowBlk">
                                            <div className="preApproveHead">Check Pre-Approved Offer</div>
                                            <div className="preApproveDesc">Provide your details below to check the Pre-Approved Offer on your Mobile Number<br />specially curated for you</div>

                                            <LoanTypeSelection className="preApproveLoanType" loanTypes={loanTypes} loanTypeId={loanTypeId} loanTypeName={loanTypeName} />

                                            <BorrowerDetails row={row?.borrower} errors={errors} salesPerson={salesPerson} onInputChanged={OnInputChanged}
                                                onBlurError={OnBlurError} onInputClicked={OnInputClicked} OnSalesPersonChanged={OnSalesPersonChanged} />

                                            {/* Co-Borrower Details */}
                                            {Helper.ToBool(isCoborrower) &&
                                                <CoBorrowerDetails row={row?.coBorrower} errors={errors} salesPerson={salesPerson} onInputChanged={OnInputChanged}
                                                    onBlurError={OnBlurError} onInputClicked={OnInputClicked} OnSalesPersonChanged={OnSalesPersonChanged} />
                                            }

                                            <RenderUploadDocument rootClassName='preApprove_borderBottom' documents={row?.documentproof} onFileItemDelete={OnFileItemDelete}
                                                doctypes={docProofTypes} onInputChanged={OnInputChanged} />

                                            <div className="preApproveButLabel">
                                                {!otpSent && (
                                                    <input
                                                        type="button"
                                                        value="Get OTP"
                                                        className="but_preApprove"
                                                        onClick={(e) => OnGetOtpClicked(e)}
                                                    />
                                                )}
                                                {/* <input type="button" value="Cancel" className="but_preApprove" /> */}
                                            </div>

                                            <ul className="contentIIcol">
                                                <li className="contentIIcol">
                                                    <div className="preApproveContentHead">Enter OTP Number</div><div className="formMandatory">*</div>
                                                    <div className="preApproveTxtField">
                                                        <input type="text" value={Helper.ToString(otpNumber)} onChange={(e) => setOtpNumber(e.target.value)} />
                                                    </div>
                                                </li>
                                            </ul>

                                            <div className="preApproveButLabel">
                                                {otpSent && (
                                                    <>
                                                        {reSendOtp && (
                                                            <input
                                                                type="button"
                                                                value="Re-Send OTP"
                                                                className="but_preApprove"
                                                                onClick={(e) => OnGetOtpClicked(e)}
                                                            />
                                                        )}
                                                        <input
                                                            type="button"
                                                            value="Submit"
                                                            className="but_preApprove"
                                                            onClick={(e) => OnCheckOfferClicked(e)}
                                                        />
                                                    </>
                                                )}
                                            </div>

                                            <div className="disclaimerAlert">
                                                <div className="disclaimerLink">
                                                    <input type="checkbox" id="acceptDisclaimer" checked={true} onChange={() => { }} />
                                                    <label htmlFor="acceptDisclaimer">
                                                        Upon proceeding, I accept CIBIL, T&C, Privacy Policy...
                                                    </label>
                                                    <span onClick={() => handleReadMore()} style={{ color: '#1f76a8', cursor: 'pointer', fontWeight: 'bold' }}>
                                                        {isExpanded ? ' Read Less' : ' Read More'}
                                                    </span>
                                                </div>
                                                {isExpanded && (
                                                    <div className="disclaimerText">
                                                        I grant my consent Moham Capital Private Limited, to fetch/utilize my KYC records from CKYCR & Credit Information from bureaus and for further sharing the information obtained with parties specified in Privacy Policy for lending purposes. I confirm that I shall not use this money for investing or trading. I agree that I have read through the Fair Practise Code of the lender.<br /><br />
                                                        <u>About Loans</u><br />
                                                        Powered by Moham Capital Private Limited<br />
                                                        Registered with Reserve Bank Of India<br />
                                                        NBFC Registration: N02.00300<br />
                                                        CIN: N - U65990KA2017PTC108573<br /><br />
                                                        Registered Office<br />10, 5th Cross, 16th Main Road, KSRTC Layout J P Nagar 2nd Phase, Bengaluru, Karnataka -560078
                                                    </div>
                                                )}
                                            </div>

                                            {error && <div className="errormessage">{error}</div>}
                                            {success && <div className="successmessage">{success}</div>}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;