import { React, Helper, DropDown, FileInput } from "common";

const Component = (props) => {

    const { documents, errors, doctypes, onInputChanged, onFileItemDelete } = props;

    const [docTypeList, setDocTypeList] = React.useState([]);
    const [selectedDocType, setSelectedDocType] = React.useState(null);
    const [filterDocTypeList, setFilterDocTypeList] = React.useState([]);
    const [uploadedDocTypes, setUploadedDocTypes] = React.useState([]);

    let rootpath = 'documentproof';

    const OnInputChange = (e) => {
        const value = e.value;
        const name = selectedDocType.name;
        setUploadedDocTypes((prev) => ([...prev, selectedDocType._id]));
        if (onInputChanged) onInputChanged(`${rootpath}_${name}`, value);
    };

    const OnStateChanged = () => { };

    const OnInputSelectChanged = async (_, value) => {
        setSelectedDocType(null);
        const item = doctypes.find(x => x._id === value);
        if (item) setSelectedDocType(item);
    }

    const OnFileItemDelete = (e, name) => {
        e.preventDefault();
        let _id = doctypes.find(x => x.name === name)?._id;
        let tmp = uploadedDocTypes;
        tmp = tmp.filter(x => x !== _id);
        setUploadedDocTypes(tmp);
        setSelectedDocType(null);
        if (onFileItemDelete) onFileItemDelete(name);
    }

    const GetLabel = (name) => {
        return doctypes.find(x => x.name === name)?.label;
    }

    React.useEffect(() => {

        let items = [{ _id: 0, label: "Select Document Type" }];
        if (doctypes && doctypes.length > 0) {
            doctypes.filter(x => x.display).map(z => {
                let tmp = { _id: z._id, label: z.label };
                items.push(tmp);
            });
        }

        setFilterDocTypeList(items);
        setDocTypeList(items);

    }, [doctypes]);

    React.useEffect(() => {

        let _completed = uploadedDocTypes;
        if (_completed.length > 0) {
            let _tmp = docTypeList.filter(x => _completed.indexOf(x._id) === -1);
            setFilterDocTypeList(_tmp);
        } else {
            setFilterDocTypeList(docTypeList);
        }

    }, [uploadedDocTypes]);

    return (
        <div>
            <div className="applyHead_borderBottom">
                Document Upload
            </div>
            <div className="uploadDoc">
                <ul className="uploadDocHeadBlk">
                    <li className="uploadDocHeadBlk">
                        <div className="uploadHeading">Document Name</div>
                        <div className="selectDocType">

                            <DropDown
                                path={`${rootpath}.docType`}
                                items={filterDocTypeList}
                                value={Helper.ToString(selectedDocType?._id)}
                                onInputChildChanged={OnInputSelectChanged}
                                displayName={"label"}
                                displayValue={"_id"}
                            />

                        </div>
                    </li>
                    <FileInput
                        readOnly={!selectedDocType}
                        id={"docfile"}
                        name={"docfile"}
                        accept={[".PNG", ".JPG", ".PDF"]}
                        onInputChanged={OnInputChange}
                        onStateChanged={OnStateChanged}
                    />
                </ul>

                <div className="uploadSubHead">
                    Uploaded Documents
                </div>
                <div className="appcontentarea_table_border">
                    {Helper.IsJSONEmpty(documents) && <div className="noContent">No documents available</div>}
                    {!Helper.IsJSONEmpty(documents) &&
                        <div className="viewDocTable">
                            <table>
                                <tbody>
                                    {Object.entries(documents).map((x, i) => (
                                        <tr key={i}>
                                            <td>{GetLabel(x[0])}</td>
                                            <td>
                                                <input type="button" value="Delete" className="viewDoc_btn" onClick={(e) => OnFileItemDelete(e, x[0])} />
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Component;