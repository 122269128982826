import { React, Link, Container, useTimerSession } from "common";

const Component = () => {

    const [token] = useTimerSession('token');
    const contentId = token ? 'content' : 'content_fullHeader';

    return (
        <>
            <Container>
                <div id="container" >
                    <div id={contentId}>
                        {!token && (
                            <div id="heroImg_about">
                                <div className="inPgBcShadeBlk">
                                    <div className="inPgBcCrossBlk">
                                        <div className="widthfix_10px">
                                            <ul className="bc_IIcol">
                                                <li className="bc_IIcol">
                                                    <div className="inPgBcHalfBlk">
                                                        <div className="inPgBcTextBlk">
                                                            <Link to="/"><div className="bcMainTxt">Home</div></Link>
                                                            <div className="bcSubTxt">/ Management Team</div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="bc_IIcol">
                                                    <ul className="bcPointsSlanting">
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Strong network of our branches</li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">More than 5 lakh satisfied customers</li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Diversified loan options for every need</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="aboutUs">
                            <div className="widthfix_10px">
                                <ul className="aboutIIcol">
                                    <li className="aboutIIcol">
                                        <ul className="aboutLeftNavi">
                                            <li className="aboutLeftNavi">
                                                <Link to="/about">About Company</Link>
                                            </li>
                                            <li className="aboutLeftNavi">
                                                <Link to="#" className="current">Management Team</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="aboutIIcol">
                                        {/* <div className="aboutHeadText">Meet The Team</div> */}

                                        <ul className="abtMgmtBlk">
                                            <li className="abtMgmtBlk">
                                                <div className="abtMgmtPhotoBg"><div className="Img_Sujay"></div></div>
                                            </li>
                                            <li className="abtMgmtBlk">
                                                <div className="abtMgmtName">Sujay Kanth</div>
                                                <div className="abtMgmtDeg">Group CEO</div>
                                                <div className="abtMgmtText">A proven visionary with strong leadership skills who knows how to transform.</div>
                                                <div className="abtMgmtText">An expert in enhancing Business Growth by developing strategic sales initiatives, implementing sales action plans that enriches growth; that provide responsible green solutions, environmentally friendly business practices to build a strong positive relationship within the companies local & global community.</div>
                                            </li>
                                            <li className="abtMgmtBlk">
                                                <div className="abtMgmtPhotoBg"><div className="Img_Muralidhara"></div></div>
                                            </li>
                                            <li className="abtMgmtBlk">
                                                <div className="abtMgmtName">Muralidhara MN</div>
                                                <div className="abtMgmtDeg">Business Director</div>
                                                <div className="abtMgmtText">An agriculture sciences graduate from University of Agricultural Sciences Bangalore and banker by profession, served in Canara Bank for 36 years in various dimensions and in all functions starting from credit to other latest areas like mutual funds, insurance, auditing, retail finance, etc. Now retired from the bank as Asst. General Manager.</div>
                                            </li>
                                            <li className="abtMgmtBlk">
                                                <div className="abtMgmtPhotoBg"><div className="Img_Girish"></div></div>
                                            </li>
                                            <li className="abtMgmtBlk">
                                                <div className="abtMgmtName">Girish Gowda</div>
                                                <div className="abtMgmtDeg">General Manager</div>
                                                <div className="abtMgmtText">A law graduate and has been part of the parent company since 2005.</div>
                                                <div className="abtMgmtText">In his 17 years' experience, he has held various administrative and management responsibilities with company ventures including Banking, Title Insurance, Automation, Automobile, E-commerce, Real-estate, and Construction including planning concepts for System Development. He brings robust organizational skills, team building and has an excessive understanding of product, administration, training, and servicing clients.</div>
                                            </li>
                                            <li className="abtMgmtBlk">
                                                <div className="abtMgmtPhotoBg"><div className="Img_Arun"></div></div>
                                            </li>
                                            <li className="abtMgmtBlk">
                                                <div className="abtMgmtName">Arun Kumar PC</div>
                                                <div className="abtMgmtDeg">Business Development</div>
                                                <div className="abtMgmtText">He is an B.TECH and MBA, A Serial entrepreneur and investor in various ventures which includes Automobile, Finance, Manufacturing, E-Commerce. He is one of the lead investors in Moham Capital.</div>
                                            </li>
                                            {/* <li className="abtMgmtBlk">
                                                <div className="abtMgmtPhotoBg"><div className="Img_Ravi"></div></div>
                                            </li>
                                            <li className="abtMgmtBlk">
                                                <div className="abtMgmtName">Ravi Rao</div>
                                                <div className="abtMgmtDeg">Business Operations Head</div>
                                                <div className="abtMgmtText">Qualified in Bachelor of Arts and a Certified Associate of Indian Institute of Bankers. Having more than 30 years’ of experience in banking field; mostly in credit administration and credit appraisal. Has functioned in both public and private sectors undertakings. Retired from Induslnd bank and was the Regional Manager at Goa and South Maharashtra region.</div>
                                            </li> */}

                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
