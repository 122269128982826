const Component = () => {
    return (
        <>
            <div id="alertDialog" className="pop_disable_bg">
                <div className="authorize_pop" style={{ minWidth: 250, width: "auto" }}>
                    <div className="pop_head">Information</div>
                    <div className="pop_contentArea" id="alertContent">Popup Content</div>
                    <div className="pop_buttonLabel">
                        <input
                            type="button"
                            value="Close"
                            className="pop_button"
                            onClick={(e) => window.hide("alertDialog")}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Component;
