const Component = () => {
  return (
    <>
      <div id="popDialog" className="pop_disable_bg">
        <div className="authorize_pop">
          <div className="pop_head">Popup Dialog</div>
          <div className="pop_contentArea">Popup Content</div>
          <div className="pop_buttonLabel">
            <input
              type="button"
              value="Submit"
              style={{ marginRight: 5 }}
              className="pop_button"
            />
            <input
              type="button"
              value="Cancel"
              className="pop_button"
              onClick={(e) => window.hide("popDialog")}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Component;
