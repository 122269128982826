import React from "react";
import RendorRoutes from "./routes";

import { Session } from "./helper";

global.Busy = (bBusy) => {
    var x = document.getElementById("busyloader");
    if (x) {
        if (bBusy) {
            x.className = "loader display-block";
        } else {
            x.className = "loader display-none";
        }
    }
};

global.RemoveLoanTypeSelection = () => {
    Session.Remove('loanTypeId');
    Session.Remove('loanTypeName');
}

const DefaultSession = () => {

    const user = { custId: "1692684923091", name: "SIVALINGAM S" };
    const statusid = 1;
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0ZjcwYWU2MWE5NjAxMTc4MDRkMWM1MiIsImVtYWlsSWQiOiJhamF5LmErdGVzdDJAdXR0YXJhbmEuY29tIiwicGhvbmVObyI6Ijk0NDM0NDQ4MzMiLCJuYW1lIjoiU0lWQUxJTkdBTSBTIiwiYWFkaGFyIjo2MTQyMjI5ODgzOTksInBhbiI6Ik5PUEFOQ0FSRCIsImN1c3RJZCI6IjE2OTM5MTE3NzAyNjQifSwiaWF0IjoxNzAzMDUzMTM0LCJleHAiOjE3MDY2NTMxMzQsInN1YiI6IjY0ZjcwYWU2MWE5NjAxMTc4MDRkMWM1MiJ9.Sff41a4MIfFsHzLP6WJgcQOjZykdSzDuL-cWsBkolsc";

    sessionStorage.setItem("user", JSON.stringify(user));
    sessionStorage.setItem("statusid", statusid);
    sessionStorage.setItem("token", token);
}

const RemoveSession = () => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("statusid");
    sessionStorage.removeItem("token");
}

function App() {
    // ragnrb+2@gmail.com
    // DefaultSession();
    return <RendorRoutes />;
}

export default App;
