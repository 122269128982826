import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Apply from "screens/apply";
import PreApproval from "screens/preapproval";
import EMICalculator from "screens/emicalculator";
import Profile from "screens/profile";

import {
  About, Branch, Career, Contact, Eligibility, FairPractice,
  Management, Privacy, Terms, BikeLoanQuote, Refund,
  ChargesBusiness, ChargesBike, ChargesGold, Agent
} from "screens/generic";

import {
  GenericLoans, BikeLoan, GoldLoan, ConsumerLoan, PropertyLoan, BusinessLoan
} from "screens/generic/loan";

import GoldLoanReview from "screens/generic/loan/goldloan/reviews";

// Generic Page Links
import Home from "screens/home";

// Dashboards
import { CustomerDashBoard } from "screens/dashboards";
import { CustomerLoans } from "screens/loans";
import { CustomerLoanDetails } from "screens/loandetails";


const RouteLinks = [
  { id: 1, path: "", Component: Home },
  { id: 2, path: "gloans", Component: GenericLoans },
  { id: 3, path: "bikeloan", Component: BikeLoan },
  { id: 4, path: "goldloan", Component: GoldLoan },
  { id: 5, path: "consumerloan", Component: ConsumerLoan },
  { id: 6, path: "propertyloan", Component: PropertyLoan },
  { id: 7, path: "businessloan", Component: BusinessLoan },
  { id: 8, path: "apply", Component: Apply },
  { id: 9, path: "about", Component: About },
  { id: 10, path: "management", Component: Management },
  { id: 12, path: "fairpractice", Component: FairPractice },
  { id: 13, path: "contact", Component: Contact },
  { id: 14, path: "preapproval", Component: PreApproval },
  { id: 15, path: "privacy", Component: Privacy },
  { id: 16, path: "terms", Component: Terms },
  { id: 17, path: "career", Component: Career },
  { id: 18, path: "emi", Component: EMICalculator },
  { id: 19, path: "eligibility", Component: Eligibility },
  { id: 20, path: "branch", Component: Branch },
  { id: 21, path: "cd", Component: CustomerDashBoard, Session: true },
  { id: 22, path: "pf", Component: Profile, Session: true },
  { id: 23, path: "cl", Component: CustomerLoans.ActiveLoans, Session: true },
  { id: 24, path: "cld", Component: CustomerLoanDetails, Session: true },
  { id: 24, path: "ccl", Component: CustomerLoans.ClosedLoans, Session: true },
  { id: 25, path: "goldloanreview", Component: GoldLoanReview },
  { id: 26, path: "bikeloanquote", Component: BikeLoanQuote },
  { id: 27, path: "refund", Component: Refund },
  { id: 30, path: "chargesbusiness", Component: ChargesBusiness },
  { id: 31, path: "chargesbike", Component: ChargesBike },
  { id: 32, path: "chargesgold", Component: ChargesGold },
  { id: 26, path: "agent", Component: Agent }
];

function PrivateRoute({ children }) {
  let loggedin = sessionStorage.getItem("user");
  return loggedin ? children : <Navigate to="/" />;
}

const RendorRoutes = () => {
  return (
    <Routes>
      {RouteLinks.map((x) => {
        if (x.Session) {
          return <Route key={x.id} path={"/" + x.path} exact
            element={
              <PrivateRoute>
                <x.Component />
              </PrivateRoute>
            }
          />
        } else {
          return <Route key={x.id} path={"/" + x.path} exact
            element={<x.Component />} />
        }
      })}
    </Routes>
  );
};

export default RendorRoutes;