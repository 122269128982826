import { React, Link, Helper, Container, useTimerSession } from "common";
import { JobsApi } from "services";
import { ApplyJobForm } from "screens/shared/components";

const RenderApplyJob = ({ jobId, onClosedClicked }) => {
    if (Helper.IsNullValue(jobId)) {
        return <ApplyJobForm noclose={true} onClosedClicked={onClosedClicked} />;
    }
    return (
        <div id="jobApply_pop" className="pop_disable_bg" style={{ display: "block" }}>
            <div className="jobApply_pop">
                <div className="jobApply_ContentArea">
                    <div className="jobApply_Head">Apply to Moham Capital</div>
                    <ApplyJobForm jobId={jobId} noclose={false} onClosedClicked={onClosedClicked} />
                </div>
            </div>
        </div>
    )
}

const RenderJobItem = (props) => {
    const { job, onApplyJob } = props;
    const [showDetails, setShowDetails] = React.useState(false);

    return (
        <li className="availableJobs">
            <div className="jobName">{job?.title}</div>
            <ul className="jobLocExp">
                <li className="jobLocExp">
                    <div className="jobExpIcon"></div>
                    <div className="jobLocExpText">{job?.experience}</div>
                </li>
                {job?.location && (
                    <li className="jobLocExp">
                        <div className="jobLocIcon"></div>
                        <div className="jobLocExpText">{job?.location}</div>
                    </li>
                )}
                <li className="jobLocExp">
                    <div className="jobSalaryIcon"></div>
                    <div className="jobLocExpText">
                        {Helper.ToInteger(job?.salary) === -1 ? "Not Disclosed" : job?.salary}
                    </div>
                </li>
            </ul>
            <div className="jdHead">Job Description -</div>
            <div className="jd">
                {job?.description}...
                {showDetails &&
                    <div>
                        {job?.responsibilities && job.responsibilities.length > 0 && (
                            <>
                                <div className="jdHead">Job Responsibilities -</div>
                                <ul className="jdbullet">
                                    {job.responsibilities.map((x, index) => (
                                        <li key={index} className="jd_circle">{x}</li>
                                    ))}
                                </ul>
                            </>
                        )}

                        {job?.requirements && job.requirements.length > 0 && (
                            <>
                                <div className="jdHead">Job Requirements -</div>
                                <ul className="jdbullet">
                                    {job.requirements.map((x, index) => (
                                        <li key={index} className="jd_circle">{x}</li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                }
                <Link onClick={() => setShowDetails(!showDetails)} to="#">{showDetails ? "Read Less" : "Read More"}</Link>
            </div>
            <div className="jobApply_Btn_Label">
                <input type="button" className="jobApply_button" value="Apply Now" onClick={(e) => onApplyJob(e, job?._id)} />
            </div>
        </li >
    );
}

const Component = () => {
    const [initlized, setInitlized] = React.useState(false);
    const [jobs, setJobs] = React.useState([]);
    const [jobId, setJobId] = React.useState(null);

    const [token] = useTimerSession('token');
    const contentId = token ? 'content' : 'content_fullHeader';

    const OnApplyJob = (e, id) => {
        e.preventDefault();
        setJobId(id);
    }

    const OnClosedClicked = (e) => {
        e.preventDefault();
        setJobId(null);
    }

    const FetchJobs = async () => {
        setJobs([]);
        global.Busy(true);
        let rslt = await JobsApi.GetAllJobs();
        global.Busy(false);
        if (rslt.status === 100) {
            setJobs(rslt.data);
        }
    }

    if (initlized) {
        setInitlized(false);
        setJobs([]);
        FetchJobs();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    return (
        <>
            <Container>
                <div id="container" >
                    <div id={contentId}>
                        {!token && (
                            <div id="heroImg_career">
                                <div className="inPgBcShadeBlk">
                                    <div className="inPgBcCrossBlk">
                                        <div className="widthfix_10px">
                                            <ul className="bc_IIcol">
                                                <li className="bc_IIcol">
                                                    <div className="inPgBcHalfBlk">
                                                        <div className="inPgBcTextBlk">
                                                            <Link to="/"><div className="bcMainTxt">Home</div></Link>
                                                            <div className="bcSubTxt">/ Career Opportunity</div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="bc_IIcol">
                                                    <ul className="bcPointsSlanting">
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Significant potential for professional growth</li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Work on impactful projects and initiatives </li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Remote work options and flexible schedules</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="career">
                            <div className="widthfix_10px">
                                <ul className="careerIIcol">
                                    <li className="careerIIcol">
                                        <div className="careerShadowBlk">
                                            {jobs && jobs.length === 0 && <div className="careerHead_left">No Jobs are available</div>}
                                            {jobs && jobs.length > 0 && <>
                                                <div className="careerHead_left">Available Jobs</div>
                                                <ul className="availableJobs">
                                                    {jobs.map((x, index) => (
                                                        <RenderJobItem key={index} job={x} onApplyJob={OnApplyJob} />
                                                    ))}
                                                </ul>
                                            </>}
                                        </div>
                                    </li>
                                    <li className="careerIIcol">
                                        <div className="careerShadowBlkUpload">
                                            <div className="careerHead">Join the Team</div>
                                            <div className="careerDesc">Please provide your contact details, we will connect. <br />If we have a role that aligns your career goal, we will get back.</div>
                                            <RenderApplyJob onClosedClicked={OnClosedClicked} />
                                        </div>
                                    </li>
                                </ul>
                            </div >
                        </div>
                    </div>
                </div>
                {!Helper.IsNullValue(jobId) && <RenderApplyJob jobId={jobId} onClosedClicked={OnClosedClicked} />}
            </Container>
        </>
    )
}

export default Component;
