import { React, Support, Link, Helper, DropDown, TextInput } from "common";
import { LookUpApi } from "services";
import DisclaimerPop from "screens/shared/popups/disclaimerPop";

const RenderTwoWheelerComponent = (props) => {

    const { row, errors, loanTypeName, onInputChanged, onBlurError, onInputClicked } = props;

    const path = 'twowheeler';
    const [initlized, setInitlized] = React.useState(false);

    const [vehicleManufacturers, setVehicleManufacturers] = React.useState([]);
    const [vehicleModels, setVehicleModels] = React.useState([]);
    const [vehicleVariants, setVehicleVariants] = React.useState([]);
    const [vehicleStates, setVehicleStates] = React.useState([]);
    const [vehicleCityDealers, setVehicleCityDealers] = React.useState([]);
    const [vehicleCities, setVehicleCities] = React.useState([]);
    const [vehicleDealers, setVehicleDealers] = React.useState([]);
    const [stateChanged, setStateChanged] = React.useState(false)
    const [state, setState] = React.useState(false);

    const FetchResults = async () => {
        global.Busy(true);
        let _t1 = await LookUpApi.GetVehicleManufacturers();
        if (_t1.status === 100) {
            Helper.AddSelectOption(_t1.data, true, 'value');
            setVehicleManufacturers(_t1.data);
        }
        let _tmp = [];
        _tmp.splice(0, 0, { _id: 0, value: "", text: "Select" });
        setVehicleModels(_tmp);
        setVehicleVariants(_tmp);
        global.Busy(false);
    };

    const DisclaimerPopUp = async (e) => {
        e.preventDefault();
        document.getElementById("disclaimer_pop").style.display = "block";
    };

    if (initlized) {
        setInitlized(false);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    React.useEffect(() => {
        const fn = async () => {
            global.Busy(true);
            let mapId = vehicleManufacturers.find(x => (x.value) === row.vehicleManufacturer)?._id;
            if (mapId) {
                let _t2 = await LookUpApi.GetVehicleModels(mapId);
                if (_t2.status === 100) {
                    Helper.AddSelectOption(_t2.data, true, 'value');
                    setVehicleModels(_t2.data);
                } else {
                    let _tmp = [];
                    _tmp.splice(0, 0, { _id: 0, value: "", text: "Select" });
                    setVehicleModels(_tmp);
                }
            }
            global.Busy(false);
        }

        if (!Helper.IsNullValue(row?.vehicleManufacturer)) fn();
    }, [row?.vehicleManufacturer, vehicleManufacturers]);

    React.useEffect(() => {
        const fn = async () => {
            global.Busy(true);
            let mapId = vehicleModels.find(x => (x.value) === row.vehicleModel)?._id;
            if (mapId) {
                let _t2 = await LookUpApi.GetVehicleVariants(mapId);
                if (_t2.status === 100) {
                    Helper.AddSelectOption(_t2.data, true, 'value');
                    setVehicleVariants(_t2.data);
                } else {
                    let _tmp = [];
                    _tmp.splice(0, 0, { _id: 0, value: "", text: "Select" });
                    setVehicleModels(_tmp);
                }
            }
            global.Busy(false);
        }

        if (!Helper.IsNullValue(row?.vehicleModel)) fn();
    }, [row?.vehicleModel, vehicleModels]);

    React.useEffect(() => {
        const fn = async () => {
            let prices = vehicleVariants.find(x => (x.value) === row.vehicleVariant)?.prices;
            if (prices) {
                for (let t of prices) {
                    t.value = t.state;
                    t.text = t.state;
                }
                Helper.AddSelectOption(prices, true, 'state');
                setVehicleStates(prices);
            }
        };
        if (!Helper.IsNullValue(row?.vehicleVariant)) fn();
    }, [row?.vehicleVariant, vehicleVariants]);

    React.useEffect(() => {
        const fn = async () => {
            let price = vehicleStates.find(x => (x.value) === row.vehicleState)?.price;
            onInputChanged(`${path}_vehiclePrice`, price);
            setStateChanged(!stateChanged);
        };
        if (!Helper.IsNullValue(row?.vehicleState)) fn();
    }, [row?.vehicleState, vehicleStates]);

    React.useEffect(() => {
        if (!Helper.IsNullValue(row?.downpayment) && !Helper.IsNullValue(row?.vehiclePrice)) {
            let _downpayment = row?.downpayment;
            let _vehiclePrice = row?.vehiclePrice;
            row.loanAmount = _vehiclePrice - _downpayment;
            setState(!state);
        }
    }, [row?.downpayment, row?.vehiclePrice])

    React.useEffect(() => {
        const fn = async () => {
            global.Busy(true);

            let mapId = vehicleManufacturers.find(x => (x.value) === row.vehicleManufacturer)?._id;
            let _state = row?.vehicleState;
            if (mapId && _state) {
                let _t2 = await LookUpApi.GetVehicleCityDealers(mapId, _state);
                if (_t2.status === 100) {
                    _t2 = _t2.data;
                    setVehicleCityDealers(_t2);
                    let _duplicateKeys = {};
                    let _cities = [];
                    let _index = 1;
                    for (let obj of _t2) {
                        let _key = obj["city"];
                        _index++;
                        if (!_duplicateKeys[_key]) {
                            Helper.AddFieldOption(_cities, _index + 1, _index, obj["city"]);
                            _duplicateKeys[_key] = true;
                        }
                    }
                    Helper.AddSelectOption(_cities, true, 'value')
                    setVehicleCities(_cities);
                } else {
                    let _tmp = [];
                    _tmp.splice(0, 0, { _id: 0, value: "", text: "Select" });
                    setVehicleCities(_tmp);
                }
            }
            global.Busy(false);
        }
        if (!Helper.IsNullValue(row?.vehicleState) && !Helper.IsNullValue(row?.vehicleVariant)) fn();
    }, [stateChanged]);

    React.useEffect(() => {
        const fn = () => {
            let _dealers = vehicleCityDealers.filter((x) => x.city === row?.vehicleCity)
                .map((x, index) => ({
                    _id: index + 1,
                    text: x["dealer"],
                    value: x["dealer"]
                }));
            Helper.AddSelectOption(_dealers, true, 'value');
            let _lastIndex = _dealers.length;
            Helper.AddFieldOption(_dealers, _lastIndex, _lastIndex, "Others");
            setVehicleDealers(_dealers);
        }
        if (!Helper.IsNullValue(row?.vehicleCity)) fn();
    }, [row?.vehicleCity])

    return (
        <>
            <div className="applyShadowBlk">
                <div className="applyHead_borderBottom">
                    Two Wheeler/Loan Details
                </div>
                <ul className="contentIIIcol">
                    <li className="contentIIIcol">
                        <div className="applyContentHead">Loan Type</div><div className="formMandatory">*</div>
                        <div className="applyTxtField">
                            <input type="text" value={Helper.ToString(loanTypeName)} onChange={() => { }} readOnly />
                        </div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="applyContentHead">Vehicle Manufacturer</div><div className="formMandatory">*</div>
                        <div className="applySelectField">
                            <DropDown
                                path={`${path}.vehicleManufacturer`}
                                value={row?.vehicleManufacturer}
                                onInputChildChanged={onInputChanged}
                                items={vehicleManufacturers}
                                displayName={"text"}
                                displayValue={"value"}
                                errors={errors}
                                dataRequired={true}
                                indexValue={1}
                                requiredMessage={"Vehicle Manufacturer is required"}
                            />
                        </div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="applyContentHead">Vehicle Model</div><div className="formMandatory">*</div>
                        <div className="applySelectField">
                            <DropDown
                                path={`${path}.vehicleModel`}
                                value={row?.vehicleModel}
                                onInputChildChanged={onInputChanged}
                                items={vehicleModels}
                                displayName={"text"}
                                displayValue={"value"}
                                errors={errors}
                                disabled={Helper.IsNullValue(row?.vehicleManufacturer)}
                                dataRequired={true}
                                indexValue={1}
                                requiredMessage={"Vehicle Model is required"}
                            />
                        </div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="applyContentHead">Vehicle Variant</div><div className="formMandatory">*</div>
                        <div className="applySelectField">
                            <DropDown
                                path={`${path}.vehicleVariant`}
                                value={row?.vehicleVariant}
                                onInputChildChanged={onInputChanged}
                                items={vehicleVariants}
                                displayName={"text"}
                                displayValue={"value"}
                                errors={errors}
                                disabled={Helper.IsNullValue(row?.vehicleModel)}
                                dataRequired={true}
                                indexValue={1}
                                requiredMessage={"Vehicle Variant is required"}
                            />
                        </div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="applyContentHead">Vehicle State</div><div className="formMandatory">*</div>
                        <div className="applySelectField">
                            <DropDown
                                path={`${path}.vehicleState`}
                                value={row?.vehicleState}
                                onInputChildChanged={onInputChanged}
                                items={vehicleStates}
                                displayName={"text"}
                                displayValue={"value"}
                                errors={errors}
                                disabled={Helper.IsNullValue(row?.vehicleVariant)}
                                dataRequired={true}
                                indexValue={1}
                                requiredMessage={"Vehicle State is required"}
                            />
                        </div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="applyContentHead">City / Town</div><div className="formMandatory">*</div>
                        <div className="applySelectField">
                            <DropDown
                                path={`${path}.vehicleCity`}
                                value={row?.vehicleCity}
                                onInputChildChanged={onInputChanged}
                                items={vehicleCities}
                                displayName={"text"}
                                displayValue={"value"}
                                errors={errors}
                                disabled={Helper.IsNullValue(row?.vehicleState)}
                                dataRequired={true}
                                indexValue={1}
                                requiredMessage={"Vehicle City is required"}
                            />
                        </div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="applyContentHead">Dealer Name</div><div className="formMandatory">*</div>
                        <div className="applySelectField">
                            <DropDown
                                path={`${path}.vehicleDealer`}
                                value={row?.vehicleDealer}
                                onInputChildChanged={onInputChanged}
                                items={vehicleDealers}
                                displayName={"text"}
                                displayValue={"value"}
                                errors={errors}
                                disabled={Helper.IsNullValue(row?.vehicleState)}
                                dataRequired={true}
                                indexValue={1}
                                requiredMessage={"Vehicle City is required"}
                            />
                        </div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="applyContentHead">Vehicle Price</div><div className="formMandatory">*</div>
                        <Link to="#" onClick={(e) => DisclaimerPopUp(e)}><div className="infoIcon"></div></Link>
                        <div className="applyTxtField">
                            <TextInput
                                errors={errors}
                                path={`${path}.vehiclePrice`}
                                valuetype={'NUMBER'}
                                value={row?.vehiclePrice}
                                onInputChildChanged={onInputChanged}
                                onBlurError={onBlurError}
                                onInputClicked={onInputClicked}
                                requiredMessage={"Vehicle Price is required"}
                                dataRequired={true}
                                required={true}
                            />
                        </div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="applyContentHead">Down Payment</div><div className="formMandatory">*</div>
                        <div className="applyTxtField">
                            <TextInput
                                errors={errors}
                                path={`${path}.downpayment`}
                                valuetype={'NUMBER'}
                                value={row?.downpayment}
                                onInputChildChanged={onInputChanged}
                                onBlurError={onBlurError}
                                onInputClicked={onInputClicked}
                                requiredMessage={'Down Payment is required'}
                                dataRequired={true}
                                required={true}
                            />
                        </div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="applyContentHead">Payment Option</div><div className="formMandatory">*</div>
                        <div className="applySelectField">
                            <DropDown
                                path={`${path}.paymentoption`}
                                value={row?.paymentoption}
                                onInputChildChanged={onInputChanged}
                                items={Support.PaymentOptions()}
                                displayName={"text"}
                                displayValue={"value"}
                                errors={errors}
                                dataRequired={true}
                                indexValue={1}
                                requiredMessage={"Payment Option is required"}
                            />
                        </div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="applyContentHead">Required Loan Amount</div><div className="formMandatory">*</div>
                        <div className="applyTxtField">
                            <TextInput
                                errors={errors}
                                path={`${path}.loanAmount`}
                                valuetype={'NUMBER'}
                                value={row?.loanAmount}
                                onInputChildChanged={onInputChanged}
                                onBlurError={onBlurError}
                                onInputClicked={onInputClicked}
                                requiredMessage={'Loan Amount is required'}
                                dataRequired={true}
                                required={true}
                                readOnly={true}
                            />
                        </div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="applyContentHead">Processing / Doc Fee</div>
                        <div className="applyTxtField">
                            <TextInput
                                errors={errors}
                                path={`${path}.processingFee`}
                                valuetype={'NUMBER'}
                                value={row?.processingFee}
                                onInputChildChanged={onInputChanged}
                                onBlurError={onBlurError}
                                onInputClicked={onInputClicked}
                                requiredMessage={'processingFee is required'}
                                dataRequired={false}
                                required={false}
                            />
                        </div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="applyContentHead">Contacted Salesperson ?</div>
                        <div className="applySelectField">
                            <DropDown
                                path={`${path}.salesPerson`}
                                value={row?.salesPerson}
                                onInputChildChanged={onInputChanged}
                                items={Support.SalesPersonOptions()}
                                displayName={"text"}
                                displayValue={"value"}
                            />
                        </div>
                    </li>
                    <li className="contentIIIcol">
                        <div className="applyContentHead">Sales Person Name</div>
                        <div className="applyTxtField">
                            <TextInput
                                errors={errors}
                                path={`${path}.salesPersonName`}
                                value={Helper.ToBool(row?.salesPerson) ? row?.salesPersonName : null}
                                onInputChildChanged={onInputChanged}
                                onInputClicked={onInputClicked}
                                dataRequired={false}
                                required={false}
                                readOnly={!Helper.ToBool(row?.salesPerson)}
                            />
                        </div>
                    </li>
                </ul>
            </div>
            <DisclaimerPop />
        </>
    );

}

export default RenderTwoWheelerComponent;