import API from "./api";

const GetAllJobs = async (input) => {
    try {
        const res = await API.get("jobs", input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const ApplyJob = async (input) => {
    try {
        const res = await API.post(`jobs/apply`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const AddJobPostings = async (input) => {
    try {
        const res = await API.post(`jobs/job`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export { ApplyJob, GetAllJobs, AddJobPostings };
