import { React, useEffect, Container } from "common";
import { HomeVideo, HomeViewBottom } from "./childs";

const Component = () => {

    useEffect(() => {
        global.RemoveLoanTypeSelection();
    }, []);

    return (
        <Container nofooter={true}>
            {/*Home Video*/}
            <HomeVideo />

            {/*Homepage Bottom*/}
            <HomeViewBottom />
        </Container>
    );
};

export default Component;
