import { React, Link, Helper, Container, TextInput, useTimerSession } from "common";
import { CommonApi } from "services";

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [row, setRow] = React.useState({});
    const [state, setState] = React.useState(false);
    const [success, setSuccess] = React.useState(null);

    const [errors, setErrors] = React.useState([]);
    const [error, setError] = React.useState(null);

    const [token] = useTimerSession('token');
    const contentId = token ? 'content' : 'content_fullHeader';

    const OnBlurError = (name, val, clear) => {
        setSuccess(null);
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        setErrors(_errors);
        setError(val);
    };

    const OnInputChanged = async (path, value, index) => {
        let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        setRow(newRow);
        setState(!state);
    }

    const OnInputClicked = (name) => {
        setSuccess(null);
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    const OnRecordValidate = () => {
        setError("");
        setErrors([]);
        const { errors, valid } = Helper.IsFormValid('container');

        if (!valid) {
            setErrors(errors);
            setError("Fill all required details");
            return false;
        }
        return true;
    };

    const OnSubmitClicked = async (e) => {
        e.preventDefault();
        if (!OnRecordValidate()) return;

        global.Busy(true);
        let rslt = await CommonApi.Contact(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setErrors(rslt.errors);
            setError(rslt.statusText);
            return;
        } else {
            setSuccess(rslt.statusText);
            setRow({});
        }
    }

    if (initlized) {
        setInitlized(false);
        setErrors([]);
        setError(null);
        setSuccess(null);
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    return (
        <>
            <Container>
                <div id="container" >
                    <div id={contentId}>
                        {!token && (
                            <div id="heroImg_contact">
                                <div className="inPgBcShadeBlk">
                                    <div className="inPgBcCrossBlk">
                                        <div className="widthfix_10px">
                                            <ul className="bc_IIcol">
                                                <li className="bc_IIcol">
                                                    <div className="inPgBcHalfBlk">
                                                        <div className="inPgBcTextBlk">
                                                            <Link to="/"><div className="bcMainTxt">Home</div></Link>
                                                            <div className="bcSubTxt">/ Contact Information</div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="bc_IIcol">
                                                    <ul className="bcPointsSlanting">
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">We're just a call away, let's connect!</li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">24/7 support team to clarify doubts</li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Your satisfaction is our top priority</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="contact">
                            <div className="widthfix_10px">
                                <ul className="contactIIcol">
                                    <li className="contactIIcol">
                                        <div className="contactShadowBlk">
                                            <ul className="iconTextBlk">
                                                <li className="iconTextBlk">
                                                    <div className="contact_ph_icon"></div>
                                                </li>
                                                <li className="iconTextBlk">
                                                    <div className="contactTxtHead">Call us directly at:</div>
                                                    <div className="contPhEmailTxt">+91 80 2649 6555</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="contactShadowBlk">
                                            <ul className="iconTextBlk">
                                                <li className="iconTextBlk">
                                                    <div className="contact_email_icon"></div>
                                                </li>
                                                <li className="iconTextBlk">
                                                    <div className="contactTxtHead">Email us at:</div>
                                                    <div className="contPhEmailTxt">info@mohamcapital.com</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="contactShadowBlk">
                                            <ul className="iconTextBlk">
                                                <li className="iconTextBlk">
                                                    <div className="contact_address_icon"></div>
                                                </li>
                                                <li className="iconTextBlk">
                                                    <div className="contactTxtHead">Corporate Address:</div>
                                                    <div className="contAddressTxt">No.10, 5th Cross, 16th Main, <br />KSRTC Layout, J.P Nagar II Phase, <br />Bangalore - 560078</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="contactIIcol">
                                        <div className="contactShadowBlk">
                                            <div className="contactFormBlk">
                                                <div className="contactFormHead">Book a Meeting</div>
                                                <div className="contactFormDesc">To schedule a meeting with one of our sales representative, please complete the form and submit. <br />Our executive will be in touch with you shortly.</div>
                                                <ul className="contactForm">
                                                    <li className="contactForm">
                                                        <div className="formContentHead">First Name<div className="formMandatory">*</div></div>
                                                        <div className="contactTxtField">
                                                            <TextInput
                                                                path={`firstName`}
                                                                value={row?.firstName}
                                                                onInputChildChanged={OnInputChanged}
                                                                onBlurError={OnBlurError}
                                                                onInputClicked={OnInputClicked}
                                                                errors={errors}
                                                                requiredMessage={'First Name is required'}
                                                                dataRequired={true}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </li>
                                                    <li className="contactForm">
                                                        <div className="formContentHead">Last Name<div className="formMandatory">*</div></div>
                                                        <div className="contactTxtField">
                                                            <TextInput
                                                                path={`lastName`}
                                                                value={row?.lastName}
                                                                onInputChildChanged={OnInputChanged}
                                                                onBlurError={OnBlurError}
                                                                onInputClicked={OnInputClicked}
                                                                errors={errors}
                                                                requiredMessage={'Last Name is required'}
                                                                dataRequired={true}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </li>
                                                    <li className="contactForm">
                                                        <div className="formContentHead">Email<div className="formMandatory">*</div></div>
                                                        <div className="contactTxtField">
                                                            <TextInput
                                                                path={`email`}
                                                                value={row?.email}
                                                                valuetype={"EMAIL"}
                                                                onInputChildChanged={OnInputChanged}
                                                                onBlurError={OnBlurError}
                                                                onInputClicked={OnInputClicked}
                                                                errors={errors}
                                                                requiredMessage={'Email-Id is required'}
                                                                validationMessage={'Enter valid Email-Id'}
                                                                dataRequired={true}
                                                                required={true}
                                                                validate={true}
                                                            />
                                                        </div>
                                                    </li>
                                                    <li className="contactForm">
                                                        <div className="formContentHead">Phone Number<div className="formMandatory">*</div></div>
                                                        <div className="contactTxtField">
                                                            <TextInput
                                                                path={`phone`}
                                                                value={row?.phone}
                                                                valuetype={"PHONE"}
                                                                onInputChildChanged={OnInputChanged}
                                                                onBlurError={OnBlurError}
                                                                onInputClicked={OnInputClicked}
                                                                errors={errors}
                                                                requiredMessage={'Phone Number is required'}
                                                                validationMessage={'Enter valid Phone Number'}
                                                                dataRequired={true}
                                                                required={true}
                                                                validate={true}
                                                            />
                                                        </div>
                                                    </li>
                                                </ul>
                                                <input
                                                    type="button"
                                                    className="contact_button"
                                                    value="Submit"
                                                    onClick={(e) => OnSubmitClicked(e)}
                                                />
                                            </div>
                                            {error && <div className="errormessage">{error}</div>}
                                            {success && <div className="successmessage">{success}</div>}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="widthfix_10px" style={{ display: "none" }}>
                            <ul className="IIcol_100by400">
                                <li className="IIcol_100by400">
                                    <div className="pgTxtBold_L">Please drop us a line, we would love to hear from you</div>
                                    <div className="pgTxtNormal">One of our loan officers will contact you for a brief consultation to find the best home loan solution for you.</div>
                                    <div className="contactFormNoShadow">
                                        <ul className="contactForm_IIcol">
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead_Error">Name</div><div className="contactFormMandatory">*</div>
                                                <div className="contactFormUserIcon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Phone Number</div><div className="contactFormMandatory">*</div>
                                                <div className="contactFormPhIcon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Email</div>
                                                <div className="contactFormMailIcon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Zip Code</div>
                                                <div className="contactFormHomeIcon"></div>
                                                <input type="text" />
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">City</div>
                                                <div className="contactFormLocationIcon"></div>
                                                <select name="filterType" defaultValue={'DEFAULT'} required >
                                                    <option value="DEFAULT" disabled>Select</option>
                                                    <option value="#">Alabama</option>
                                                    <option value="#">California</option>
                                                    <option value="#">Florida</option>
                                                </select>
                                            </li>
                                            <li className="contactForm_IIcol">
                                                <div className="contactFormHead">Loan Type</div>
                                                <div className="contactFormTypeIcon"></div>
                                                <select name="filterType" defaultValue={'DEFAULT'} required >
                                                    <option value="DEFAULT" disabled>Select</option>
                                                    <option value="#">Purchase</option>
                                                    <option value="#">Refinance</option>
                                                    <option value="#">Others</option>
                                                </select>
                                            </li>
                                        </ul>
                                        <div className="contactForm_Icol">
                                            <div className="contactFormHead">Message</div>
                                            <div className="contactFormNoteIcon"></div>
                                            <textarea></textarea>
                                        </div>
                                        <div className="contactForm_btn_label">
                                            <input type="button" value="Reach Us" className="contactFormBtn" />
                                        </div>
                                        <div className="errormessage" style={{ display: "none" }}>You have some errors. Please fix and proceed.</div>

                                        <div className="successmessage" style={{ display: "none" }}>Thank you for contacting us. We will get back to you ASAP.</div>
                                    </div>
                                </li>
                                <li className="IIcol_100by400">
                                    {/* <ContactInfo /> */}
                                </li>
                            </ul >
                        </div >

                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
