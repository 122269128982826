import { React, Support, DropDown, TextInput, DatePicker } from "common";

const RenderPersonaDetails = (props) => {

    const { row, errors, onInputChanged, onBlurError, onInputClicked, headtext } = props;

    let rootpath = 'personaldetails';

    return (
        <>
            <div className="applyHead_borderBottom">
                Personal Details
            </div>
            <ul className="contentIIIcol">
                <li className="contentIIIcol">
                    <div className="applyContentHead">First Name</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.firstName`}
                            value={row?.firstName}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'First Name is required'}
                            dataRequired={true}
                            required={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Middle Name</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.middleName`}
                            value={row?.middleName}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Last Name</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.lastName`}
                            value={row?.lastName}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'Last Name is required'}
                            dataRequired={true}
                            required={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Phone Number</div><div className="formMandatory">*</div>
                    <div className="applyNoteText">OTP Will Be Sent</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.phoneNumber`}
                            valuetype={"PHONE"}
                            value={row?.phoneNumber}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'Phone Number is required'}
                            validationMessage={'Enter valid Phone Number'}
                            dataRequired={true}
                            validate={true}
                            required={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Email Address</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.emailAddress`}
                            valuetype={"EMAIL"}
                            value={row?.emailAddress}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            validationMessage={'Enter valid email-id'}
                            dataRequired={false}
                            validate={true}
                            required={false}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">DOB</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <DatePicker
                            errors={errors}
                            path={`${rootpath}.birthDate`}
                            placeholder="Date Of Birth"
                            value={row?.birthDate}
                            valuetype={'DOB'}
                            age={18}
                            dateFormat="dd/MM/yyyy"
                            required={true}
                            requiredMessage={'Date of Birth is required'}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Credit Score</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.creditscore`}
                            value={row?.creditscore}
                            onInputChildChanged={onInputChanged}
                            items={Support.CreditTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Credit Score is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Marital Status</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.maritalStatus`}
                            value={row?.maritalStatus}
                            onInputChildChanged={onInputChanged}
                            items={Support.MaritalTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Marital Status is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Gender</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.gender`}
                            value={row?.gender}
                            onInputChildChanged={onInputChanged}
                            items={Support.GenderTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Gender is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Education</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.education`}
                            value={row?.education}
                            onInputChildChanged={onInputChanged}
                            items={Support.EducationTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Education is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Vehicle Owned</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.vehicalowned`}
                            value={row?.vehicalowned}
                            onInputChildChanged={onInputChanged}
                            items={Support.VehicleOwnedTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Vehicle Owned is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Make</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.make`}
                            value={row?.make}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            dataRequired={false}
                            required={false}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Model</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.model`}
                            value={row?.model}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            dataRequired={false}
                            required={false}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">PAN</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.pan`}
                            valuetype={"PAN"}
                            value={row?.pan}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'PAN is required'}
                            validationMessage={'Enter valid PAN'}
                            dataRequired={false}
                            required={false}
                            validate={true}
                            uppercase={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Aadhar</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.aadhar`}
                            valuetype={"AADHAR"}
                            value={row?.aadhar}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'Aadhar is required'}
                            validationMessage={'Enter valid Aadhar'}
                            dataRequired={true}
                            required={true}
                            validate={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Voter ID</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.voterid`}
                            valuetype={"VOTERID"}
                            value={row?.voterid}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            validationMessage={'Enter valid Voter ID'}
                            dataRequired={false}
                            required={false}
                            validate={true}
                            uppercase={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Net Monthly Income</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.monthlyIncome`}
                            value={row?.monthlyIncome}
                            onInputChildChanged={onInputChanged}
                            items={Support.MonthlyIncomeTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Net Monthly Income is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Any Existing Loan</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.existingLoan`}
                            value={row?.existingLoan}
                            onInputChildChanged={onInputChanged}
                            items={Support.ExistingLoanTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            indexValue={1}
                            requiredMessage={"Any Existing Loan is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Dependent If Any</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.dependent`}
                            value={row?.dependent}
                            onInputChildChanged={onInputChanged}
                            items={Support.DependentTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            indexValue={1}
                            requiredMessage={"Dependent If Any is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Father's Name</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.fatherName`}
                            value={row?.fatherName}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={`Father's Name is required`}
                            dataRequired={true}
                            required={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Mother's Name</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.motherName`}
                            value={row?.motherName}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={`Mother's Name is required`}
                            dataRequired={true}
                            required={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Co-Borrower If Any</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.coborrower`}
                            value={row?.coborrower}
                            onInputChildChanged={onInputChanged}
                            items={Support.CoBorrowerTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Guarantor If Any</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.guarantor`}
                            value={row?.guarantor}
                            onInputChildChanged={onInputChanged}
                            items={Support.GuarantorTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">How did you hear about MCPL</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.hearabout`}
                            value={row?.hearabout}
                            onInputChildChanged={onInputChanged}
                            items={Support.HearAboutTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                        />
                    </div>
                </li>
            </ul>
            <div className="spacer_20margin_bottom"></div>
        </>
    );

}

export default RenderPersonaDetails;