import { Link } from "react-router-dom";

const Component = () => {
    return (
        <div id="footer">
            <div className="footer_blk">
                <div className="widthfix">
                    <div className="footerInpage">
                        Moham Capital Pvt. Ltd. &copy; {new Date().getFullYear()} | All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Component;