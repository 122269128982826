import { React, useEffect, Link, Helper, Container, useState } from "common";
import { CommonApi } from "services";

const Component = () => {

    const [row, setRow] = useState({ _id: "", name: "", phone: "", otpnum: "" });
    const [received, setReceived] = useState(false);
    const [errors, setErrors] = useState([]);
    const [error, setError] = useState("");

    useEffect(() => {
        ResetValues();
    }, []);

    const ResetValues = () => {
        setRow({ _id: "", name: "", phone: "", otpnum: "" });
        setReceived(false);
        setErrors([]);
        setError("");
    };

    const OnInputChanged = (name, value) => {
        setRow((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    const OnGetOtpClicked = async (e) => {
        e.preventDefault();

        setError("");
        setErrors([]);
        let _errors = [];
        if (Helper.IsNullValue(row.name)) _errors.push("name");
        if (Helper.IsNullValue(row.phone)) _errors.push("phone");
        if (_errors.length > 0) {
            setErrors(_errors);
            return;
        }

        global.Busy(true);
        let rslt = await CommonApi.GetOtp("mobile", row.phone);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }
        OnInputChanged('_id', rslt.data);
        setReceived(true);
    }

    const OnVerifyClicked = async (e) => {
        e.preventDefault();
        setError("");
        setErrors([]);

        let _errors = [];
        if (Helper.IsNullValue(row.otpnum) || row.otpnum.length !== 4) _errors.push("otpnum");
        if (_errors.length > 0) {
            setErrors(_errors);
            return;
        }

        global.Busy(true);
        let rslt = await CommonApi.AddEnquiry(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }

        ResetValues();

        window.showAlert("Thank You. <br />Our team will contact you asap.");
    };

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_loan">
                            <div className="inPgBcShadeBlk">
                                <div className="inPgBcCrossBlk">
                                    <div className="widthfix_10px">
                                        <ul className="bc_IIcol">
                                            <li className="bc_IIcol">
                                                <div className="inPgBcHalfBlk">
                                                    <div className="inPgBcTextBlk">
                                                        <Link to="/"><div className="bcMainTxt">Home</div></Link>
                                                        <div className="bcSubTxt">/ Loan Eligibility</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="bc_IIcol">
                                                <ul className="bcPointsSlanting">
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Provides buyers with increased confidence</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Speed up the complete loan process</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Give buyers more negotiating power</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="eligibility">
                            <div className="widthfix_10px">
                                <ul className="loginBlk">
                                    <li className="loginBlk">
                                        <div className="loginContent">
                                            <div className="loginTextHeading">Simple and Secure.</div>
                                            <div className="loginTextHeading">Verify Your Number to Check Your Loan Eligibility</div>
                                            <div className="loginTextDesc">You can trust us with your information. <br />Our commitment to privacy: your information is completely secure with us.</div>
                                        </div>
                                    </li>
                                    <li className="loginBlk">
                                        <div className="loginForm">
                                            {/* <div className="mcplLogo loginMCPLlogoPos"></div> */}
                                            <div className="loginFormBlkHeading">Welcome to Moham Capital</div>
                                            <div className="loginFormTxtField">
                                                <input
                                                    type="text"
                                                    placeholder="Name"
                                                    value={Helper.ToString(row.name)}
                                                    style={{
                                                        borderColor:
                                                            errors && errors.indexOf("name") > -1
                                                                ? "#ff0000"
                                                                : undefined,
                                                    }}
                                                    onChange={(e) => OnInputChanged("name", e.target.value)}
                                                />
                                            </div>

                                            {!received &&
                                                <div className="textFieldEligibilityButton">
                                                    <input type="button" className="buttonEligibility_getOTP" value="Get OTP" onClick={(e) => OnGetOtpClicked(e)} />
                                                </div>
                                            }

                                            <div className="loginFormTxtField">
                                                <input
                                                    type="text"
                                                    placeholder="Phone Number"
                                                    value={Helper.ToString(row.phone)}
                                                    style={{
                                                        borderColor:
                                                            errors && errors.indexOf("phone") > -1
                                                                ? "#ff0000"
                                                                : undefined,
                                                    }}
                                                    onChange={(e) => OnInputChanged("phone", e.target.value)}
                                                    onKeyPress={(e) => {
                                                        return Helper.AcceptedChars(e, "NUMBERS");
                                                    }}
                                                />
                                            </div>

                                            {received && (
                                                <>
                                                    <div className="loginFormTxtField">
                                                        <input
                                                            maxLength={4}
                                                            placeholder="OTP"
                                                            type="text"
                                                            value={Helper.ToString(row.otpnum)}
                                                            style={{
                                                                borderColor:
                                                                    errors && errors.indexOf("otpnum") > -1
                                                                        ? "#ff0000"
                                                                        : undefined,
                                                            }}
                                                            onChange={(e) => OnInputChanged("otpnum", e.target.value)}
                                                            onKeyPress={(e) => {
                                                                return Helper.AcceptedChars(e, "NUMBERS");
                                                            }}
                                                        />
                                                    </div>
                                                    <input type="button" className="loginForm_button" value="Login" onClick={(e) => OnVerifyClicked(e)} />
                                                </>
                                            )}
                                            <div className="termsofuse_text">By loggig in, you're agreeing to our Terms Of Use</div>
                                            {error && (
                                                <div
                                                    className="errormessage"
                                                    style={{ marginTop: 5, marginBottom: 5 }}
                                                >
                                                    {error}
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container >
        </>
    )
}

export default Component;