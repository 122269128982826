import { React, Container, Link, Session, useNavigate } from "common";
import { LookUpApi } from "services";

const Component = () => {

    const Navigate = useNavigate();
    const [loanTypes, setLoanTypes] = React.useState([]);
    const [initlized, setInitlized] = React.useState(false);

    const OnLoanTypeSelected = (e, type, name) => {
        e.preventDefault();
        Session.Store('loanTypeId', type);
        Session.Store('loanTypeName', name);
        Navigate("/apply");
    }

    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await LookUpApi.GetLoanTypes();
        if (rslt.status === 100) {
            setLoanTypes(rslt.data);
        }
        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_loan">
                            <div className="inPgBcShadeBlk">
                                <div className="inPgBcCrossBlk">
                                    <div className="widthfix_10px">
                                        <ul className="bc_IIcol">
                                            <li className="bc_IIcol">
                                                <div className="inPgBcHalfBlk">
                                                    <div className="inPgBcTextBlkNoSub">
                                                        <Link to="/"><div className="bcMainTxt">Home</div></Link>
                                                        <div className="bcSubTxt">/ Loans</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="bc_IIcol">
                                                <ul className="bcPointsSlanting">
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Serving our customers with best of offerings</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Get 100% financing on your dream purchase</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Customized solutions for every financial need</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="loanInpg">
                            <div className="widthfix_10px">
                                <div className="inPgHeadTxt">Moham Capital's Product Offerings</div>
                                <ul className="loanVer5col">
                                    {loanTypes && loanTypes.map((x) => {
                                        return (
                                            <li className="loanVer5col" key={x._id}>
                                                <Link to={x.loantype} title={x.displayname}><div className={x.classname}></div></Link>
                                                <div className="loanName">{x.displayname}</div>
                                                <div className="loanDesc">{x.description}</div>
                                                <Link to="/apply" onClick={(e) => OnLoanTypeSelected(e, x.value, x.displayname)}><input type="button" value="Apply Now" className="loanApply_button" /></Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div >
                </div >
            </Container>
        </>
    )
}

export default Component;