const LoanTypes = [
  { label: "Two Wheeler Loan", value: 1, checked: false },
  { label: "Gold Loan", value: 2, checked: false },
  { label: "Loan Against Property", value: 3, checked: false },
  { label: "Consumer Durables Loan", value: 4, checked: false },
  { label: "Business Loan", value: 5, checked: false },
];

const CreditTypes = () => {
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "720 and above", value: "720 and above" },
    { id: 2, text: "660 - 719", value: "660 - 719" },
    { id: 3, text: "620 - 659", value: "620 - 659" },
    { id: 4, text: "580 - 619", value: "580 - 619" },
    { id: 5, text: "579 or below", value: "579 or below" },
    { id: 5, text: "Credit Score Not Known", value: "Credit Score Not Known" },
  ];
};

const MaritalTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "Married", value: "Married" },
      { id: 2, text: "Unmarried", value: "Unmarried" },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Married", value: "Married" },
    { id: 2, text: "Unmarried", value: "Unmarried" },
  ];
};

const GenderTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "Male", value: "Male" },
      { id: 2, text: "Female", value: "Female" },
      { id: 3, text: "Transgender", value: "Transgender" },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Male", value: "Male" },
    { id: 2, text: "Female", value: "Female" },
    { id: 3, text: "Transgender", value: "Transgender" },
  ];
};

const EducationTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "Under Graduate", value: "Under Graduate" },
      { id: 2, text: "Graduate", value: "Graduate" },
      { id: 3, text: "Post Graduate", value: "Post Graduate" },
      { id: 4, text: "Diploma Holder", value: "Diploma Holder" },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Under Graduate", value: "Under Graduate" },
    { id: 2, text: "Graduate", value: "Graduate" },
    { id: 3, text: "Post Graduate", value: "Post Graduate" },
    { id: 4, text: "Diploma Holder", value: "Diploma Holder" },
  ];
};

const VehicleOwnedTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "2 Wheeler", value: "2 Wheeler" },
      { id: 2, text: "4 Wheeler", value: "4 Wheeler" },
      { id: 3, text: "Both", value: "Both" },
      { id: 4, text: "None", value: "None" },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "2 Wheeler", value: "2 Wheeler" },
    { id: 2, text: "4 Wheeler", value: "4 Wheeler" },
    { id: 3, text: "Both", value: "Both" },
    { id: 4, text: "None", value: "None" },
  ];
};

const MonthlyIncomeTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "< Rs. 15,000", value: "< Rs. 15,000" },
      { id: 2, text: "Rs. 15,000 - 25,000", value: "Rs. 15,000 - 25,000" },
      { id: 3, text: "Rs. 25,000 - 50,000", value: "Rs. 25,000 - 50,000" },
      { id: 4, text: "Rs. 50,000 - 75,000", value: "Rs. 50,000 - 75,000" },
      { id: 5, text: "Rs. 75,000 - 1,00,000", value: "Rs. 75,000 - 1,00,000" },
      { id: 6, text: "Rs. 1,00,000 - 1,50,000", value: "Rs. 1,00,000 - 1,50,000" },
      { id: 7, text: "> 1,50,000", value: "> 1,50,000" }
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "< Rs. 15,000", value: "< Rs. 15,000" },
    { id: 2, text: "Rs. 15,000 - 25,000", value: "Rs. 15,000 - 25,000" },
    { id: 3, text: "Rs. 25,000 - 50,000", value: "Rs. 25,000 - 50,000" },
    { id: 4, text: "Rs. 50,000 - 75,000", value: "Rs. 50,000 - 75,000" },
    { id: 5, text: "Rs. 75,000 - 1,00,000", value: "Rs. 75,000 - 1,00,000" },
    { id: 6, text: "Rs. 1,00,000 - 1,50,000", value: "Rs. 1,00,000 - 1,50,000" },
    { id: 7, text: "> 1,50,000", value: "> 1,50,000" }
  ];
};

const ExistingLoanTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "Yes", value: true },
      { id: 2, text: "No", value: false },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Yes", value: true },
    { id: 2, text: "No", value: false },
  ];
};

const DependentTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "Yes", value: true },
      { id: 2, text: "No", value: false },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Yes", value: true },
    { id: 2, text: "No", value: false },
  ];
};

const OccupantTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "Owned", value: "Owned" },
      { id: 2, text: "Rented", value: "Rented" },
      { id: 3, text: "Co. Provided", value: "Co. Provided" },
      { id: 4, text: "Parental", value: "Parental" },
      { id: 5, text: "Bachelor Acc / Sharing / PG", value: "Bachelor Acc / Sharing / PG" },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Owned", value: "Owned" },
    { id: 2, text: "Rented", value: "Rented" },
    { id: 3, text: "Co. Provided", value: "Co. Provided" },
    { id: 4, text: "Parental", value: "Parental" },
    { id: 5, text: "Bachelor Acc / Sharing / PG", value: "Bachelor Acc / Sharing / PG" },
  ];
};

const CityTypes = [
  { _id: 0, name: "Select", value: "", text: "Select" },
  { _id: 1, name: "Bangalore", value: "Bangalore", text: "Bangalore" },
  { _id: 2, name: "Chennai", value: "Chennai", text: "Chennai" },
]

const StateTypes = [
  { _id: 0, name: "Select", value: "", text: "Select" },
  { _id: 1, name: "Karnataka", value: "Karnataka", text: "Karnataka" },
  { _id: 2, name: "Tamil Nadu", value: "Tamil Nadu", text: "Tamil Nadu" },
]

const ResidenceTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "Less Than 1 Year", value: "Less Than 1 Year" },
      { id: 2, text: "1 Year - 3 Years", value: "1 Year - 3 Years" },
      { id: 3, text: "4 Years - 7 Years", value: "4 Years - 7 Years" },
      { id: 4, text: "8 Years - 10 Years", value: "8 Years - 10 Years" },
      { id: 5, text: "More Than 10 Years", value: "More Than 10 Years" },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Less Than 1 Year", value: "Less Than 1 Year" },
    { id: 2, text: "1 Year - 3 Years", value: "1 Year - 3 Years" },
    { id: 3, text: "4 Years - 7 Years", value: "4 Years - 7 Years" },
    { id: 4, text: "8 Years - 10 Years", value: "8 Years - 10 Years" },
    { id: 5, text: "More Than 10 Years", value: "More Than 10 Years" },
  ];
};

const EmplomentTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "Self-employed", value: "Self-employed" },
      { id: 2, text: "Salaried", value: "Salaried" },
      { id: 3, text: "Housewife", value: "Housewife" },
      { id: 4, text: "Student", value: "Student" },
      { id: 5, text: "Pensioner", value: "Pensioner" },
      { id: 6, text: "Farmer", value: "Farmer" },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Self-employed", value: "Self-employed" },
    { id: 2, text: "Salaried", value: "Salaried" },
    { id: 3, text: "Housewife", value: "Housewife" },
    { id: 4, text: "Student", value: "Student" },
    { id: 5, text: "Pensioner", value: "Pensioner" },
    { id: 6, text: "Farmer", value: "Farmer" },
  ];
};

const ProfessionTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "Self-employed", value: "Self-employed" },
      { id: 2, text: "Professional", value: "Professional" },
      { id: 3, text: "Architect", value: "Architect" },
      { id: 4, text: "Doctor", value: "Doctor" },
      { id: 5, text: "CA", value: "CA" },
      { id: 6, text: "CS", value: "CS" },
      { id: 7, text: "Engineer", value: "Engineer" },
      { id: 8, text: "Dentist", value: "Dentist" },
      { id: 9, text: "Consultant", value: "Consultant" },
      { id: 10, text: "Freelancer", value: "Freelancer" },
      { id: 11, text: "Broker", value: "Broker" },
      { id: 12, text: "Other", value: "Other" },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Self-employed", value: "Self-employed" },
    { id: 2, text: "Professional", value: "Professional" },
    { id: 3, text: "Architect", value: "Architect" },
    { id: 4, text: "Doctor", value: "Doctor" },
    { id: 5, text: "CA", value: "CA" },
    { id: 6, text: "CS", value: "CS" },
    { id: 7, text: "Engineer", value: "Engineer" },
    { id: 8, text: "Dentist", value: "Dentist" },
    { id: 9, text: "Consultant", value: "Consultant" },
    { id: 10, text: "Freelancer", value: "Freelancer" },
    { id: 11, text: "Broker", value: "Broker" },
    { id: 12, text: "Other", value: "Other" },
  ];
};

const IndustryTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "IT", value: "IT" },
      { id: 2, text: "Banking and Finance", value: "Banking and Finance" },
      { id: 3, text: "Govt Service", value: "Govt Service" },
      { id: 4, text: "Consulting", value: "Consulting" },
      { id: 5, text: "Telecom", value: "Telecom" },
      { id: 6, text: "BPO-KPO", value: "BPO-KPO" },
      { id: 7, text: "Manufacturing", value: "Manufacturing" },
      { id: 8, text: "Trading", value: "Trading" },
      { id: 9, text: "Services", value: "Services" },
      { id: 10, text: "Other", value: "Other" },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "IT", value: "IT" },
    { id: 2, text: "Banking and Finance", value: "Banking and Finance" },
    { id: 3, text: "Govt Service", value: "Govt Service" },
    { id: 4, text: "Consulting", value: "Consulting" },
    { id: 5, text: "Telecom", value: "Telecom" },
    { id: 6, text: "BPO-KPO", value: "BPO-KPO" },
    { id: 7, text: "Manufacturing", value: "Manufacturing" },
    { id: 8, text: "Trading", value: "Trading" },
    { id: 9, text: "Services", value: "Services" },
    { id: 10, text: "Other", value: "Other" },
  ];
};

const ShowRoomTypes = (defLabel) => {
  if (defLabel) {
    return [
      { id: 0, text: defLabel },
      { id: 1, text: "Showroom 1", value: "Showroom 1" },
      { id: 2, text: "Showroom 2", value: "Showroom 2" },
    ];
  }
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Showroom 1", value: "Showroom 1" },
    { id: 2, text: "Showroom 2", value: "Showroom 2" },
  ];
};



/* Below Items needs to move to database */
// input.splice(0, 0, { _id: 0, value: "", text: "Select" });
const vehicleManufacturers = [
  { _id: "6438dde0baa41cd575307be6", value: "6438dde0baa41cd575307be6", text: "Bajaj" },
  { _id: "6438dde0baa41cd575307be7", value: "6438dde0baa41cd575307be7", text: "Hero" },
  { _id: "6438dde0baa41cd575307be8", value: "6438dde0baa41cd575307be8", text: "Honda" },
  { _id: "6438dde0baa41cd575307be9", value: "6438dde0baa41cd575307be9", text: "TVS" },
];

const vehicleModels = [
  { _id: "6438dde0baa41cd575307bf0", mapid: "6438dde0baa41cd575307be6", value: "6438dde0baa41cd575307bf0", text: "Pulsar 150" },
  { _id: "6438dde0baa41cd575307bf1", mapid: "6438dde0baa41cd575307be6", value: "6438dde0baa41cd575307bf1", text: "Pulsar N150" },
  { _id: "6438dde0baa41cd575307bf2", mapid: "6438dde0baa41cd575307be6", value: "6438dde0baa41cd575307bf2", text: "Pulsar 250" },
  { _id: "6438dde0baa41cd575307bf3", mapid: "6438dde0baa41cd575307be6", value: "6438dde0baa41cd575307bf3", text: "Pulsar N250" },
  { _id: "6438dde0baa41cd575307bf4", mapid: "6438dde0baa41cd575307be9", value: "6438dde0baa41cd575307bf4", text: "TVS Apache RTR 150" },
  { _id: "6438dde0baa41cd575307bf5", mapid: "6438dde0baa41cd575307be9", value: "6438dde0baa41cd575307bf5", text: "TVS Apache RTR 250" },
  { _id: "6438dde0baa41cd575307bf6", mapid: "6438dde0baa41cd575307be9", value: "6438dde0baa41cd575307bf6", text: "TVS Raider 125" },
  { _id: "6438dde0baa41cd575307bf7", mapid: "6438dde0baa41cd575307be9", value: "6438dde0baa41cd575307bf7", text: "TVS Ronin" }
];

const vehicleVariants = [
  { _id: "6438dde0baa41cd575307bg0", mapid: "6438dde0baa41cd575307bf1", value: "6438dde0baa41cd575307bg0", text: "Disk" },
  { _id: "6438dde0baa41cd575307bg1", mapid: "6438dde0baa41cd575307bf1", value: "6438dde0baa41cd575307bg1", text: "Single ABS" },
  { _id: "6438dde0baa41cd575307bg2", mapid: "6438dde0baa41cd575307bf1", value: "6438dde0baa41cd575307bg2", text: "Dual ABS" }
];

const HearAboutTypes = () => {
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Social Media", value: "Social Media" },
    { id: 2, text: "Google/Search Engine", value: "Google/Search Engine" },
    { id: 3, text: "Online Campaign", value: "Online Campaign" },
    { id: 4, text: "Streaming Service Ad", value: "Streaming Service Ad" },
    { id: 5, text: "Billboard", value: "Billboard" },
    { id: 6, text: "Friend Recommended", value: "Friend Recommended" },
    { id: 7, text: "Others", value: "Others" }
  ];
};

const CoBorrowerTypes = () => {
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Yes", value: true },
    { id: 2, text: "No", value: false },
  ];
};

const GuarantorTypes = () => {
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Yes", value: true },
    { id: 2, text: "No", value: false },
  ];
};

const PaymentOptions = () => {
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Cash", value: "Cash" },
    { id: 2, text: "Cheque", value: "Cheque" },
    { id: 3, text: "Online UPI", value: "Online UPI" },
  ];
};

const SalesPersonOptions = () => {
  return [
    { id: 0, text: "Select", value: "" },
    { id: 1, text: "Yes", value: true },
    { id: 2, text: "No", value: false },
  ];
}

export {
  LoanTypes,
  MaritalTypes,
  GenderTypes,
  EducationTypes,
  VehicleOwnedTypes,
  MonthlyIncomeTypes,
  ExistingLoanTypes,
  DependentTypes,
  OccupantTypes,
  CityTypes,
  StateTypes,
  ResidenceTypes,
  EmplomentTypes,
  ProfessionTypes,
  IndustryTypes,
  ShowRoomTypes,
  vehicleManufacturers,
  vehicleModels,
  vehicleVariants,
  CreditTypes,
  HearAboutTypes,
  CoBorrowerTypes,
  GuarantorTypes,
  PaymentOptions,
  SalesPersonOptions
};
