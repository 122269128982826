import { React, Container, useState, useEffect, Helper } from "common";
import { LoanApi } from "services";

const Component = () => {

    const [onRoadPrice, setOnRoadPrice] = useState('');
    const [downPayment, setDownPayment] = useState('');
    const [loanBeforeDC, setLoanBeforeDC] = useState('');
    const [schemePercentage, setSchemePercentage] = useState('+');
    const [schemeValue, setSchemeValue] = useState(''); //to store scheme calculation
    const [documentationCharge, setDocumentationCharge] = useState('');
    const [processingFee, setProcessingFee] = useState('');
    const [verificationCharge, setVerificationCharge] = useState('');
    const [specialDiscount, setSpecialDiscount] = useState('');
    const [totalCharges, setTotalCharges] = useState('');
    const [totalChargesBeforeDiscount, setTotalChargesBeforeDiscount] = useState(''); //required for calculating discount error message
    const [loanAfterDC, setLoanAfterDC] = useState(''); //loan amount to calculate the emi
    const [activeTenure, setActiveTenure] = useState(12);
    const [emiAmount, setEmiAmount] = useState(null);
    const [totalPayableAmount, setTotalPayableAmount] = useState(null);
    const [selectedRate, setSelectedRate] = useState('9.99% Default');
    // const [activeRate, setActiveRate] = useState(9.99);
    // const interestRates = [9.99, 11.99]; (reqd for onclick interest)
    const tenures = [12, 18, 24, 30, 36];
    const [onRoadPriceError, setOnRoadPriceError] = useState('');
    const [downPaymentError, setDownPaymentError] = useState('');
    const [documentationChargeError, setDocumentationChargeError] = useState('');
    const [processingFeeError, setProcessingFeeError] = useState('');
    const [verificationChargeError, setVerificationChargeError] = useState('');
    const [specialDiscountError, setSpecialDiscountError] = useState('');
    const [isFormComplete, setIsFormComplete] = useState(false); //to check if form is filled

    //Calculation for On Road Price
    const handleOnRoadPriceChange = (event) => {
        const value = event.target.value;
        const onRoadPriceValue = parseFloat(value);
        const downPaymentValue = parseFloat(downPayment);

        const isValidNumber = !isNaN(onRoadPriceValue);

        if (!isValidNumber) {
            if (value === '') {
                if (!isNaN(downPaymentValue) && downPaymentValue > 0) {
                    setOnRoadPriceError('Please delete down payment value and fill on-road price again');
                } else {
                    setOnRoadPrice('');
                    setOnRoadPriceError('');
                }
            }
        } else if (onRoadPriceValue < 0) {
            setOnRoadPriceError('On-road price cannot be a negative value');
        } else if (!isNaN(downPaymentValue) && onRoadPriceValue < downPaymentValue) {
            setOnRoadPriceError('On-road price cannot be less than down payment');
        } else {
            setOnRoadPriceError('');
            setOnRoadPrice(value);
        }

        // Clear error message if focus moves away from the On Road Price input field
        event.target.addEventListener('blur', () => {
            setOnRoadPriceError('');
        });
    };

    //Calculation for Down Payment
    const handleDownPaymentChange = (event) => {
        const value = event.target.value;
        const downPaymentValue = parseFloat(value);

        // Check if onRoadPrice is empty
        if (onRoadPrice === '') {
            setDownPayment('');
            setDownPaymentError('Please enter on-road price first');
            return;
        }

        const isValidNumber = !isNaN(downPaymentValue);

        if (!isValidNumber) {
            if (value === '') {
                setDownPayment('');
            }
        } else if (downPaymentValue < 0) {
            setDownPaymentError('Down payment cannot be a negative value');
        } else if (downPaymentValue > parseFloat(onRoadPrice) * 0.95) {
            setDownPaymentError('Down payment cannot exceed 95% of on-road price');
        } else if (downPaymentValue > parseFloat(onRoadPrice)) {
            setDownPaymentError('Down payment cannot be more than on-road price');
        } else {
            setDownPaymentError('');
            setDownPayment(value);
        }

        // Clear error message if focus moves away from the Down payment input field
        event.target.addEventListener('blur', () => {
            setDownPaymentError('');
        });
    };

    //Calculation for Scheme
    const handleSchemePercentageChange = (event) => {
        setSchemePercentage(event.target.value);
    };

    const handleSelectedRateChange = (event) => {
        setSelectedRate(event.target.value);
    };

    const getDisplayRate = (rate) => rate.replace(' Default', '');

    useEffect(() => {
        const onRoadPriceValue = parseFloat(onRoadPrice);
        const downPaymentValue = parseFloat(downPayment);

        if (!isNaN(onRoadPriceValue) && !isNaN(downPaymentValue)) {
            setLoanBeforeDC(onRoadPriceValue - downPaymentValue);
        } else {
            setLoanBeforeDC('');
        }
    }, [onRoadPrice, downPayment]);
    useEffect(() => {
        let calculationResult;

        // Parse loan and documentation charge values
        const loanAmount = parseFloat(loanBeforeDC);
        const documentationChargeValue = parseFloat(documentationCharge);

        if (isNaN(loanAmount)) {
            setSchemeValue('');
            return;
        }

        // Parse documentation charge, default to 0 if invalid
        const validDocumentationCharge = isNaN(documentationChargeValue) ? 0 : documentationChargeValue;

        // Calculation based on schemePercentage
        switch (schemePercentage) {
            case '1%':
                calculationResult = loanAmount * 0.01;
                break;
            case '2%':
                calculationResult = loanAmount * 0.02;
                break;
            case '2.5%':
                calculationResult = loanAmount * 0.025;
                break;
            case '3%':
                calculationResult = loanAmount * 0.03;
                break;
            case '3.5%':
                calculationResult = loanAmount * 0.035;
                break;
            case '4%':
                calculationResult = loanAmount * 0.04;
                break;
            case '4.5%':
                calculationResult = loanAmount * 0.045;
                break;
            case '5%':
                calculationResult = loanAmount * 0.05;
                break;
            case '5.5%':
                calculationResult = loanAmount * 0.055;
                break;
            default:
                calculationResult = '';
                break;
        }

        // Add documentation charge to scheme calculation result
        calculationResult += validDocumentationCharge;

        setSchemeValue(calculationResult);
    }, [schemePercentage, loanBeforeDC, documentationCharge]);

    // useEffect(() => {
    //     let calculationResult;

    //     if (isNaN(parseFloat(loanBeforeDC))) {
    //         setSchemeValue('');
    //         return;
    //     }

    //     switch (schemePercentage) {
    //         case '1%':
    //             calculationResult = parseFloat(loanBeforeDC) * 0.01;
    //             break;

    //         case '2%':
    //             calculationResult = parseFloat(loanBeforeDC) * 0.02;
    //             break;

    //         case '2.5%':
    //             calculationResult = parseFloat(loanBeforeDC) * 0.025;
    //             break;

    //         case '3%':
    //             calculationResult = parseFloat(loanBeforeDC) * 0.03;
    //             break;

    //         case '3.5%':
    //             calculationResult = parseFloat(loanBeforeDC) * 0.035;
    //             break;

    //         case '4%':
    //             calculationResult = parseFloat(loanBeforeDC) * 0.04;
    //             break;

    //         case '4.5%':
    //             calculationResult = parseFloat(loanBeforeDC) * 0.045;
    //             break;

    //         case '5%':
    //             calculationResult = parseFloat(loanBeforeDC) * 0.05;
    //             break;

    //         case '5.5%':
    //             calculationResult = parseFloat(loanBeforeDC) * 0.055;
    //             break;

    //         default:
    //             calculationResult = '';
    //             break;
    //     }

    //     setSchemeValue(calculationResult);
    // }, [schemePercentage, loanBeforeDC]);

    //Calculation for Documentation Charge
    const handleDocumentationChargeChange = (event) => {
        const value = event.target.value;
        const documentationChargeValue = parseFloat(value);
        const maxAllowedCharge = parseFloat(loanBeforeDC) * 0.1;

        // Check if onRoadPrice is empty
        if (onRoadPrice === '') {
            setDocumentationCharge('');
            setDocumentationChargeError('Please enter on-road price first');
            return;
        }

        // Check if downPayment is empty
        if (downPayment === '') {
            setDocumentationCharge('');
            setDocumentationChargeError('Please enter down payment first');
            return;
        }

        const isValidNumber = !isNaN(documentationChargeValue);

        if (!isValidNumber) {
            if (value === '') {
                setDocumentationCharge('');
            }
        } else if (documentationChargeValue < 0) {
            setDocumentationChargeError('Documentation charge cannot be a negative value');
        } else if (documentationChargeValue > maxAllowedCharge) {
            setDocumentationChargeError('Documentation charge cannot exceed 10% of loan amount');
        } else {
            setDocumentationChargeError('');
            setDocumentationCharge(value);
        }

        // Clear error message if focus moves away from the Documentation Charge input field
        event.target.addEventListener('blur', () => {
            setDocumentationChargeError('');
        });
    };

    //Calculation for Processing Fee
    const handleProcessingFeeChange = (event) => {
        const value = event.target.value;
        const processingFeeValue = parseFloat(value);
        const maxAllowedFee = parseFloat(loanBeforeDC) * 0.1;

        // Check if onRoadPrice is empty
        if (onRoadPrice === '') {
            setProcessingFee('');
            setProcessingFeeError('Please enter on-road price first');
            return;
        }

        // Check if downPayment is empty
        if (downPayment === '') {
            setProcessingFee('');
            setProcessingFeeError('Please enter down payment first');
            return;
        }

        const isValidNumber = !isNaN(processingFeeValue);

        // Clear error message if focus moves away from the Processing Fee input field
        event.target.addEventListener('blur', () => {
            setProcessingFeeError('');
        });

        // if (!isValidNumber) {
        //     if (value === '') {
        //         setProcessingFeeError('');
        //         setProcessingFee(0);
        //     } else {
        //         setProcessingFeeError('');
        //     }
        // } else 

        if (!isValidNumber) {
            if (value === '') {
                setProcessingFee('');
            }
        } else if (processingFeeValue < 0) {
            setProcessingFeeError('Processing fee cannot be a negative value');
        } else if (processingFeeValue > maxAllowedFee) {
            setProcessingFeeError('Processing fee cannot exceed 10% of loan amount');
        } else {
            setProcessingFeeError('');
            setProcessingFee(processingFeeValue);
        }
    };

    //calculation for verification charge
    const handleVerificationChargeChange = (event) => {
        const value = event.target.value;
        const verificationChargeValue = parseFloat(value);
        const maxAllowedCharge = parseFloat(loanBeforeDC) * 0.1;

        // Check if onRoadPrice is empty
        if (onRoadPrice === '') {
            setVerificationCharge('');
            setVerificationChargeError('Please enter on-road price first');
            return;
        }

        // Check if downPayment is empty
        if (downPayment === '') {
            setVerificationCharge('');
            setVerificationChargeError('Please enter down payment first');
            return;
        }

        const isValidNumber = !isNaN(verificationChargeValue);

        // Clear error message if focus moves away from the verification charge input field
        event.target.addEventListener('blur', () => {
            setVerificationChargeError('');
        });

        // if (!isValidNumber) {
        //     if (value === '') {
        //         setVerificationChargeError('');
        //         setVerificationCharge(0);
        //     } else {
        //         setVerificationChargeError('');
        //     }
        // } 
        if (!isValidNumber) {
            if (value === '') {
                setVerificationCharge('');
            }
        } else if (verificationChargeValue < 0) {
            setVerificationChargeError('Verification charge cannot be a negative value');
        } else if (verificationChargeValue > maxAllowedCharge) {
            setVerificationChargeError('Verification charge cannot exceed 10% of loan amount');
        } else {
            setVerificationChargeError('');
            setVerificationCharge(verificationChargeValue);
        }
    };

    //Calculation for Special Discount
    const handleSpecialDiscountChange = (event) => {
        const value = event.target.value;
        const specialDiscountValue = parseFloat(value);

        // Check if Documentation Charge is empty
        if (documentationCharge === '') {
            setSpecialDiscount('');
            setSpecialDiscountError('Please enter documentation charges first');
            return;
        }

        // Check if Documentation Charge is empty
        if (processingFee === '') {
            setSpecialDiscount('');
            setSpecialDiscountError('Please enter processing fee first');
            return;
        }

        // Check if Verification Charge is empty
        if (verificationCharge === '') {
            setSpecialDiscount('');
            setSpecialDiscountError('Please enter verification charge first');
            return;
        }

        const isValidNumber = !isNaN(specialDiscountValue);

        // Clear error message if focus moves away from the Special Discount input field
        event.target.addEventListener('blur', () => {
            setSpecialDiscountError('');
        });

        // if (!isValidNumber) {
        //     if (value === '') {
        //         setSpecialDiscountError('');
        //         setSpecialDiscount(0);
        //     } else {
        //         setSpecialDiscountError('');
        //     }
        // } 
        if (!isValidNumber) {
            if (value === '') {
                setSpecialDiscount('');
            }
        } else if (specialDiscountValue < 0) {
            setSpecialDiscountError('Special discount cannot be a negative value');
        } else if (specialDiscountValue > parseFloat(totalChargesBeforeDiscount)) {
            setSpecialDiscountError('Special discount cannot exceed processing and HP charges');
        } else {
            setSpecialDiscountError('');
            setSpecialDiscount(specialDiscountValue);
        }
    };



    // //Calculation for Total Charges
    // useEffect(() => {
    //     const documentationChargeValue = parseFloat(documentationCharge);
    //     const processingFeeValue = parseFloat(processingFee);
    //     const verificationChargeValue = parseFloat(verificationCharge);
    //     const specialDiscountValue = parseFloat(specialDiscount);

    //     const validDocumentationCharge = isNaN(documentationChargeValue) ? 0 : documentationChargeValue;
    //     const validProcessingFee = isNaN(processingFeeValue) ? 0 : processingFeeValue;
    //     const validVerificationCharge = isNaN(verificationChargeValue) ? 0 : verificationChargeValue;
    //     const validSpecialDiscount = isNaN(specialDiscountValue) ? 0 : specialDiscountValue;

    //     const totalChargesValue = Math.max(validDocumentationCharge + validProcessingFee + validVerificationCharge - validSpecialDiscount, 0);

    //     if (!isNaN(totalChargesValue)) {
    //         setTotalCharges(totalChargesValue);
    //     } else {
    //         setTotalCharges('');
    //     }
    // }, [documentationCharge, processingFee, verificationCharge, specialDiscount]);


    useEffect(() => {
        const processingFeeValue = parseFloat(processingFee);
        const verificationChargeValue = parseFloat(verificationCharge);
        const specialDiscountValue = parseFloat(specialDiscount);

        const validProcessingFee = isNaN(processingFeeValue) ? 0 : processingFeeValue;
        const validVerificationCharge = isNaN(verificationChargeValue) ? 0 : verificationChargeValue;
        const validSpecialDiscount = isNaN(specialDiscountValue) ? 0 : specialDiscountValue;

        const totalChargesValue = Math.max(validProcessingFee + validVerificationCharge - validSpecialDiscount, 0);

        if (!isNaN(totalChargesValue)) {
            setTotalCharges(totalChargesValue);
        } else {
            setTotalCharges('');
        }
    }, [processingFee, verificationCharge, specialDiscount]);

    // useEffect(() => {
    //     const documentationChargeValue = parseFloat(documentationCharge);
    //     const processingFeeValue = parseFloat(processingFee);
    //     const verificationChargeValue = parseFloat(verificationCharge);

    //     if (!isNaN(documentationChargeValue) && !isNaN(processingFeeValue) && !isNaN(verificationChargeValue)) {
    //         setTotalCharges(documentationChargeValue + processingFeeValue + verificationChargeValue - specialDiscount);
    //     } else {
    //         setTotalCharges('');
    //     }
    // }, [documentationCharge, processingFee, verificationCharge, specialDiscount]);


    //Calculation for Total Charges before discount
    useEffect(() => {
        const documentationChargeValue = parseFloat(documentationCharge);
        const processingFeeValue = parseFloat(processingFee);
        const verificationChargeValue = parseFloat(verificationCharge);

        if (!isNaN(documentationChargeValue) && !isNaN(processingFeeValue) && !isNaN(verificationChargeValue)) {
            setTotalChargesBeforeDiscount(documentationChargeValue + processingFeeValue + verificationChargeValue);
        } else {
            setTotalChargesBeforeDiscount('');
        }
    }, [documentationCharge, processingFee, verificationCharge]);

    //Calculation for Loan Amount all inclusive
    useEffect(() => {
        const schemePercentageValue = parseFloat(schemeValue);
        const loanBeforeDCValue = parseFloat(loanBeforeDC);
        const totalChargesValue = parseFloat(totalCharges);

        if (!isNaN(loanBeforeDCValue) && !isNaN(totalChargesValue)) {
            const calculatedLoanAfterDC = isNaN(schemePercentageValue) ? loanBeforeDCValue + totalChargesValue : schemePercentageValue + loanBeforeDCValue + totalChargesValue;
            setLoanAfterDC(calculatedLoanAfterDC);
        } else {
            setLoanAfterDC('');
        }
    }, [schemeValue, loanBeforeDC, totalCharges]);

    useEffect(() => {
        if (loanAfterDC !== '') {
            calculateEMI(getDisplayRate(selectedRate), activeTenure, parseFloat(loanAfterDC));
        }
    }, [selectedRate, activeTenure, loanAfterDC]);

    //Calculation for EMI
    const calculateEMI = (rate1, tenure, amount) => {
        const principle = parseFloat(amount);
        const rate = parseFloat(rate1) / 1200;
        const time = parseFloat(tenure);
        const emiValue = Math.round((principle / time) + (principle * rate));
        const totalPayment = emiValue * time;
        const totalInterestPayable = totalPayment - principle;

        setEmiAmount(emiValue);
        // setTotalInterest(totalInterestPayable);
        setTotalPayableAmount(totalPayment);
    };

    const handleTenureClick = (tenure) => {
        setActiveTenure(tenure);
    };

    //To display Date and Time
    const [currentTime, setCurrentTime] = useState(new Date());

    const GetFormattedData = () => {
        let _currentTime = formatDate(currentTime);
        let _selectedRate = selectedRate.split('%')[0] + '%';
        return {
            environment: Helper.environment,
            onRoadPrice: Intl.NumberFormat().format(onRoadPrice), downPayment: Intl.NumberFormat().format(downPayment),
            loanBeforeDC: Intl.NumberFormat().format(loanBeforeDC), schemeValue: Intl.NumberFormat().format(schemeValue),
            totalCharges: Intl.NumberFormat().format(totalCharges), loanAfterDC: Intl.NumberFormat().format(loanAfterDC),
            rate: _selectedRate, activeTenure: activeTenure, selectedRate: _selectedRate, emiAmount: Helper.INRCurrencyFormat(emiAmount),
            loantovalue: onRoadPrice > 0 ? ((loanAfterDC / onRoadPrice) * 100).toFixed(2) + '%' : 0 + '%',
            totalPayableAmount: Helper.INRCurrencyFormat(totalPayableAmount), currentTime: _currentTime
        }
    }

    const DownLoadPdf = async (filePath) => {
        await fetch(filePath)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "MohamCapital_LoanScheme.pdf";
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }

    const OnPrintPdfClicked = async (e) => {
        e.preventDefault();

        let data = GetFormattedData();

        global.Busy(true);
        let rslt = await LoanApi.GeneratePDF(data);
        global.Busy(false);
        if (rslt.status !== 100) {
            return;
        }
        let filePath = rslt.file;
        await DownLoadPdf(filePath);
    }

    const OnSharePDFClicked = async (e) => {
        e.preventDefault();
        let data = GetFormattedData();

        global.Busy(true);
        let rslt = await LoanApi.GeneratePDF(data);
        global.Busy(false);
        if (rslt.status !== 100) {
            return;
        }
        let filePath = rslt.file;

        const response = await fetch(filePath);
        const blob = await response.blob();
        const file = new File([blob], 'MohamCapital_LoanScheme.pdf', { type: 'application/pdf' });

        if (navigator.canShare && navigator.canShare({ files: [file] }) && e.isTrusted) {
            navigator.share({
                files: [file],
                title: 'Loan Document',
                text: 'This is a sample loan document generated from an EJS template with CSS styling.'
            }).catch(async (error) => {
                console.error('Error sharing PDF:', error);
                await DownLoadPdf(filePath);
            });
        } else {
            alert('Your browser does not support sharing files.');
        }
    };

    useEffect(() => {
        // const timerId = setInterval(() => {
            setCurrentTime(new Date());
        // }, 1000);

        // return () => clearInterval(timerId); // Cleanup interval on component unmount
    }, []);

    const formatDate = (date) => {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-indexed
        const year = date.getFullYear();

        return `${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${day
                .toString()
                .padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
    };

    // Check if the form is completely filled and valid
    useEffect(() => {
        const onRoadPriceValue = parseFloat(onRoadPrice);
        const downPaymentValue = parseFloat(downPayment);
        const documentationChargeValue = parseFloat(documentationCharge);
        const processingFeeValue = parseFloat(processingFee);
        const verificationChargeValue = parseFloat(verificationCharge);

        if (
            !isNaN(onRoadPriceValue) &&
            !isNaN(downPaymentValue) &&
            !isNaN(documentationChargeValue) &&
            !isNaN(processingFeeValue) &&
            !isNaN(verificationChargeValue) &&
            onRoadPriceError === '' &&
            downPaymentError === '' &&
            documentationChargeError === '' &&
            processingFeeError === '' &&
            verificationChargeError === ''
        ) {
            setIsFormComplete(true);
        } else {
            setIsFormComplete(false);
        }
    }, [
        onRoadPrice,
        downPayment,
        documentationCharge,
        processingFee,
        verificationCharge,
        specialDiscount,
        onRoadPriceError,
        downPaymentError,
        documentationChargeError,
        processingFeeError,
        verificationChargeError,
    ]);

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className="loanQuote">
                            <div className="widthfix_10px">

                                <div className="loanQuoteHead">Tailored Bike Loan Schemes</div>
                                <div className="loanQuoteDesc">Our representative can build the best scheme based on client preferences, offering customization options to tailor specific needs</div>

                                <ul className="loanQuoteIIcol40pcby60pc">
                                    <li className="loanQuoteIIcol40pcby60pc">
                                        <div className="loanQuoteShadowBlk">
                                            <div className="loanQuoteHeading">Scheme Generation Tool</div>
                                            <ul className="loanQuoteIIcol">
                                                <li className="loanQuoteIIcol">
                                                    <div className={onRoadPriceError ? 'formContentHeadError' : 'formContentHead'}>On Road Price</div>
                                                    <div className="formMandatory">*</div>
                                                    <div className="loanQuoteTextField">
                                                        <div className="rupeeIcon"></div>
                                                        <input type="number" value={onRoadPrice} onChange={handleOnRoadPriceChange} className="no-spinner" />
                                                        {/* {onRoadPriceError && <span className="error">{onRoadPriceError}</span>} */}
                                                    </div>
                                                </li>
                                                <li className="loanQuoteIIcol">
                                                    <div className={downPaymentError ? 'formContentHeadError' : 'formContentHead'}>Down Payment</div>

                                                    <div className="formMandatory">*</div>
                                                    <div className="loanQuoteTextField">
                                                        <div className="paymentIcon"></div>
                                                        <input type="number" value={downPayment} onChange={handleDownPaymentChange} onBlur={() => setDownPaymentError('')} className="no-spinner" />
                                                    </div>
                                                </li>
                                                <li className="loanQuoteIIcol">
                                                    <div className="formContentHead">Scheme Name</div>
                                                    <div className="formMandatory">*</div>
                                                    <div className="loanQuoteSelectField">
                                                        <div className="schemeIcon"></div>
                                                        <select value={schemePercentage} defaultValue={'Default'} onChange={handleSchemePercentageChange}>
                                                            <option value="Default">Select</option>
                                                            <option value="0%">MCPL 0</option>
                                                            <option value="1%">MCPL 1.0</option>
                                                            <option value="2%">MCPL 2.0</option>
                                                            <option value="2.5%">MCPL 2.5</option>
                                                            <option value="3%">MCPL 3.0</option>
                                                            <option value="3.5%">MCPL 3.5</option>
                                                            <option value="4%">MCPL 4.0</option>
                                                            <option value="4.5%">MCPL 4.5</option>
                                                            <option value="5%">MCPL 5.0</option>
                                                            <option value="5.5%">MCPL 5.5</option>
                                                        </select>
                                                    </div>
                                                </li>
                                                <li className="loanQuoteIIcol">
                                                    <div className={documentationChargeError ? 'formContentHeadError' : 'formContentHead'}>Documentation Charge</div>
                                                    <div className="formMandatory">*</div>
                                                    <div className="loanQuoteTextField">
                                                        <div className="documentIcon"></div>
                                                        <input type="number" value={documentationCharge} onChange={handleDocumentationChargeChange} onBlur={() => setDocumentationChargeError('')} className="no-spinner" />
                                                    </div>
                                                </li>
                                                <li className="loanQuoteIIcol">
                                                    <div className={processingFeeError ? 'formContentHeadError' : 'formContentHead'}> MCPL Processing Fee</div>
                                                    <div className="formMandatory">*</div>
                                                    <div className="loanQuoteTextField">
                                                        <div className="processingIcon"></div>
                                                        <input type="number" value={processingFee} onChange={handleProcessingFeeChange} onBlur={() => setProcessingFeeError('')} className="no-spinner" />
                                                    </div>
                                                </li>
                                                <li className="loanQuoteIIcol">
                                                    <div className={verificationChargeError ? 'formContentHeadError' : 'formContentHead'}>MCPL Verification Charge</div>
                                                    <div className="formMandatory">*</div>
                                                    <div className="loanQuoteTextField">
                                                        <div className="verifyIcon"></div>
                                                        <input type="number" value={verificationCharge} onChange={handleVerificationChargeChange} onBlur={() => setVerificationChargeError('')} className="no-spinner" />
                                                    </div>
                                                </li>
                                                <li className="loanQuoteIIcol">
                                                    <div className={specialDiscountError ? 'formContentHeadError' : 'formContentHead'}>Special Discount</div>
                                                    <div className="loanQuoteTextField">
                                                        <div className="discountIcon"></div>
                                                        <input type="number" value={specialDiscount} onChange={handleSpecialDiscountChange} onBlur={() => setSpecialDiscountError('')} className="no-spinner" />
                                                    </div>
                                                </li>
                                            </ul>
                                            {onRoadPriceError && <div className="errorMsg">{onRoadPriceError}</div>}
                                            {downPaymentError && <div className="errorMsg">{downPaymentError}</div>}
                                            {documentationChargeError && <div className="errorMsg">{documentationChargeError}</div>}
                                            {processingFeeError && <div className="errorMsg">{processingFeeError}</div>}
                                            {verificationChargeError && <div className="errorMsg">{verificationChargeError}</div>}
                                            {specialDiscountError && <div className="errorMsg">{specialDiscountError}</div>}

                                            {/* <button className="loanQuote_button">Generate Scheme</button> */}
                                        </div>
                                    </li>
                                    {/* Right Block */}
                                    <li className="loanQuoteIIcol40pcby60pc">
                                        <div id="printResult">
                                            <div className="loanQuoteShadowBlk">
                                                <ul className="logoShareBtnBlock">
                                                    <li className="logoPos mcplLogo"></li>
                                                    {isFormComplete &&
                                                        <li className="shareBtnPos">
                                                            <button className="printIcon" onClick={(e) => OnPrintPdfClicked(e)}></button>
                                                            <button className="shareIcon" onClick={(e) => OnSharePDFClicked(e)}></button>
                                                        </li>
                                                    }
                                                </ul>
                                                <ul className='schemeResult'>
                                                    <li className='schemeResult'>Particulars</li>
                                                    <li className='schemeResult'>Amount</li>
                                                    <li className='schemeResult'>On Road Price</li>
                                                    <li className='schemeResult'>{Intl.NumberFormat().format(onRoadPrice)}</li>
                                                    <li className='schemeResult'>Down Payment</li>
                                                    <li className='schemeResult'>{Intl.NumberFormat().format(downPayment)}</li>
                                                    <li className='schemeResult'>Loan Amount before Charges</li>
                                                    <li className='schemeResult'>{Intl.NumberFormat().format(loanBeforeDC)}</li>
                                                    <li className='schemeResult'>Verification and Documentation Charges</li>
                                                    <li className='schemeResult'>{Intl.NumberFormat().format(schemeValue)}</li>
                                                    <li className='schemeResult'>MCPL Processing and HP Charges</li>
                                                    <li className='schemeResult'>{Intl.NumberFormat().format(totalCharges)}</li>
                                                    <li className='schemeResult'>Loan Amount with all Charges Inclusive</li>
                                                    <li className='schemeResult'>{Intl.NumberFormat().format(loanAfterDC)}</li>
                                                    <li className='schemeResult'>Loan-To-Value (LTV) Ratio</li>
                                                    <li className='schemeResult'>
                                                        {onRoadPrice > 0 ? ((loanAfterDC / onRoadPrice) * 100).toFixed(2) : 0}&nbsp;%
                                                    </li>
                                                </ul>

                                                <ul className="intRateTenureBlk">
                                                    <li className="intRateTenureBlk">
                                                        <div className="intRateTenureHead">Interest Rate</div>
                                                        <ul className="intRateStyle">
                                                            <div className="interestRateSelectField">
                                                                <select value={selectedRate} onChange={handleSelectedRateChange} defaultValue="9.99%">
                                                                    <option value="0%">0 %</option>
                                                                    <option value="9%">9 %</option>
                                                                    <option value="9.99% Default">9.99 %</option>
                                                                    <option value="10.99%">10.99 %</option>
                                                                    <option value="11.20%">11.20 %</option>
                                                                    <option value="11.80%">11.80 %</option>
                                                                    <option value="11.99%">11.99 %</option>
                                                                    <option value="12.99%">12.99 %</option>
                                                                    <option value="13.99%">13.99 %</option>
                                                                    <option value="14.99%">14.99 %</option>
                                                                    <option value="15.99%">15.99 %</option>
                                                                    <option value="18%">18%</option>
                                                                    <option value="24%">24%</option>
                                                                    <option value="36%">36%</option>
                                                                </select>
                                                            </div>

                                                            {/* {interestRates.map((rate) => (
                                                            <li
                                                                key={rate}
                                                                className={activeRate === rate ? 'active' : ''}
                                                                onClick={() => handleRateClick(rate)}
                                                            >
                                                                {rate}%
                                                            </li>
                                                        ))} */}
                                                        </ul>

                                                    </li>
                                                    <li className="intRateTenureBlk">
                                                        <div className="intRateTenureHead">Tenure (In Months)</div>
                                                        <ul className="tenureStyle">
                                                            {tenures.map((tenure) => (
                                                                <li
                                                                    key={tenure}
                                                                    className={activeTenure === tenure ? 'active' : ''}
                                                                    onClick={() => handleTenureClick(tenure)}
                                                                >
                                                                    {tenure}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </li>
                                                </ul>

                                                {/* Results */}
                                                {isFormComplete ? (
                                                    <ul className="loanResult">
                                                        <li className="loanResult">
                                                            {emiAmount !== null && (
                                                                <div>
                                                                    <div className="loanResultHeading">EMI for {getDisplayRate(selectedRate)} Int and {activeTenure} Mts</div>
                                                                    <div className="loanResultAnswer">
                                                                        ₹{Intl.NumberFormat().format(emiAmount)}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </li>

                                                        <li className="loanResult">
                                                            {totalPayableAmount !== null && (
                                                                <div>
                                                                    <div className="loanResultHeading">Total Payable Amount</div>
                                                                    <div className="loanResultAnswer">
                                                                        ₹{Intl.NumberFormat().format(totalPayableAmount)}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </li>
                                                    </ul>
                                                ) : (
                                                    <div className="fillFormMessage">Please fill the mandatory form fields to generate scheme</div>
                                                )}

                                                <div className="loanQuoteNoteText">
                                                    Any finance scheme generated online has to be approved by the credit team.
                                                    {isFormComplete ? (
                                                        <div>
                                                            This scheme is generated on <b><span style={{ color: "black" }}>{formatDate(currentTime)}</span></b> and is valid for next 24hours.
                                                        </div>
                                                    ) : (
                                                        null
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div >
                </div >
            </Container >
        </>
    )
}

export default Component;