import { React, Link, Container, Helper, CustomButton, TextInput, DropDown } from "common";
import { LookUpApi } from "services";
import SignUp from "./signup";
import { UsersApi } from "services";

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [row, setRow] = React.useState({});
    const [state, setState] = React.useState(false);
    const [loanTypes, setLoanTypes] = React.useState([]);

    const [errors, setErrors] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [success, setSuccess] = React.useState(null);

    const OnBlurError = (name, val, clear) => {
        setSuccess(null);
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        setErrors(_errors);
        setError(val);
    };

    const OnInputChanged = async (path, value, index) => {
        let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        setRow(newRow);
        setState(!state);
    }

    const OnInputClicked = (name) => {
        setSuccess(null);
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    const OnRecordValidate = () => {
        setError("");
        setErrors([]);
        const { errors, valid } = Helper.IsFormValid('referal');

        if (!valid) {
            setErrors(errors);
            setError("Fill all required details");
            return false;
        }
        return true;
    };

    const OnSubmitClicked = async (e) => {
        e.preventDefault();
        if (!OnRecordValidate()) return;

        global.Busy(true);
        let rslt = await UsersApi.AddCustomer(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setErrors(rslt.errors);
            setError(rslt.statusText);
            return;
        } else {
            setSuccess(rslt.statusText);
            setRow({});
        }
    }

    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await LookUpApi.GetLoanTypes();
        global.Busy(false);
        if (rslt.status === 100) {
            let tmp = rslt.data || [];
            tmp.splice(0, 0, { _id: 0, displayname: "", label: "Select" });
            setLoanTypes(tmp);
        }
    };

    if (initlized) {
        setInitlized(false);
        setErrors([]);
        setError(null);
        setSuccess(null);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_about">
                            <div className="inPgBcShadeBlk">
                                <div className="inPgBcCrossBlk">
                                    <div className="widthfix_10px">
                                        <ul className="bc_IIcol">
                                            <li className="bc_IIcol">
                                                <div className="inPgBcHalfBlk">
                                                    <div className="inPgBcTextBlk">
                                                        <Link to="/"><div className="bcMainTxt">Home</div></Link>
                                                        <div className="bcSubTxt">/ Agent Program</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="bc_IIcol">
                                                <ul className="bcPointsSlanting">
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Strong network of our branches</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">More than 5 lakh satisfied customers</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Diversified loan options for every need</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="agent">
                            <div className="widthfix_10px">
                                <ul className="agentIIcol">
                                    <li>
                                        <div className="agentShadowBlk">
                                            <div className="agentBlkHead">
                                                <div className="heading">Agent Referral</div>
                                            </div>
                                            <div className="agentBlkDesc">
                                                Share a lead with us and earn commission on every successful conversion!
                                            </div>

                                            <form onSubmit={OnSubmitClicked} id="referal">
                                                <ul className="agentIIcol">
                                                    <li>
                                                        <div className="form-group">
                                                            <label>Customer Name:</label>
                                                            <TextInput
                                                                path={`name`}
                                                                value={row?.name}
                                                                onInputChildChanged={OnInputChanged}
                                                                onBlurError={OnBlurError}
                                                                onInputClicked={OnInputClicked}
                                                                errors={errors}
                                                                requiredMessage={'Name is required'}
                                                                dataRequired={true}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-group">
                                                            <label>Customer Phone:</label>
                                                            <TextInput
                                                                path={`phoneNumber`}
                                                                value={row?.phoneNumber}
                                                                valuetype={"PHONE"}
                                                                onInputChildChanged={OnInputChanged}
                                                                onBlurError={OnBlurError}
                                                                onInputClicked={OnInputClicked}
                                                                errors={errors}
                                                                requiredMessage={'Phone Number is required'}
                                                                validationMessage={'Enter valid Phone Number'}
                                                                dataRequired={true}
                                                                required={true}
                                                                validate={true}
                                                            />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-group">
                                                            <label>Loan Type:</label>
                                                            <DropDown
                                                                path={`loanType`}
                                                                value={row?.loanType}
                                                                onInputChildChanged={OnInputChanged}
                                                                items={loanTypes}
                                                                displayName={"label"}
                                                                displayValue={"displayname"}
                                                                errors={errors}
                                                                dataRequired={true}
                                                                indexValue={1}
                                                                requiredMessage={"Loan Type is required"}
                                                            />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="form-group">
                                                            <label>Agent Code:</label>
                                                            <TextInput
                                                                path={`agentCode`}
                                                                value={row?.agentCode}
                                                                valuetype={"PHONE"}
                                                                onInputChildChanged={OnInputChanged}
                                                                onBlurError={OnBlurError}
                                                                onInputClicked={OnInputClicked}
                                                                errors={errors}
                                                                requiredMessage={'Agent Code is required'}
                                                                validationMessage={'Enter valid Agent Code'}
                                                                dataRequired={true}
                                                                required={true}
                                                                validate={true}
                                                            />
                                                        </div>
                                                    </li>
                                                </ul>
                                                <CustomButton type="submit" value="Submit" css="btnRed" onClick={(e) => OnSubmitClicked(e)} />
                                            </form>
                                            {error && <div className="errormessage">{error}</div>}
                                            {success && <div className="referralCreated">Referral creation successful.<br />We will call you back when the lead converts to business.</div>}
                                        </div>
                                    </li>
                                    <SignUp />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
