const Component = () => {
    return (
        <div id="changepswd_pop" className="pop_disable_bg">
            <div className="changePassword_pop">
                <div className="pop_head">Change Password</div>
                <div className="pop_contentArea">
                    <div className="pop_formFieldHead_center">Old Password</div>
                    <div className="textBox_whiteBg"><input type="password" /></div>
                    <div className="pop_formFieldHead_center">New Password</div>
                    <div className="textBox_whiteBg"><input type="text" /></div>
                    <div className="pop_formFieldHead_center">Confirm Password</div>
                    <div className="textBox_whiteBg"><input type="password" /></div>
                </div>
                <div className="pop_buttonLabel">
                    <input type="button" value="Update" className="pop_button" />
                    <input type="button" value="Close" className="pop_button" onClick={() => window.hide('changepswd_pop')} />
                </div>
                <div className="errormessage_center" style={{ display: "none" }}>All fields are mandatory</div>
            </div>

        </div>
    );
};

export default Component;