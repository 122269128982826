import { React } from "common";

const Component = () => {
    return (
        <div>
            <ul className="goldLoanratingBlk">
                <li className="goldLoanrating">4.7</li>
                <li className="goldLoanratingStars">
                    <ul className="ratingStars">
                        <li className="ratingStarsFull">&nbsp;</li>
                        <li className="ratingStarsFull">&nbsp;</li>
                        <li className="ratingStarsFull">&nbsp;</li>
                        <li className="ratingStarsFull">&nbsp;</li>
                        <li className="ratingStarsHalf">&nbsp;</li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default Component;