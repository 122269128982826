import { React, Container, Link, Session, useNavigate } from "common";
import RatingStar from "./reviews/childs/ratingstar";

const Component = () => {

    const Navigate = useNavigate();

    const OnLoanTypeSelected = (e, type, name) => {
        e.preventDefault();
        Session.Store('loanTypeId', type);
        Session.Store('loanTypeName', name);
        Navigate("/apply");
    }

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_gold">
                            <div className="inPgBcShadeBlk">
                                <div className="inPgBcCrossBlk">
                                    <div className="widthfix_10px">
                                        <ul className="bc_IIcol">
                                            <li className="bc_IIcol">
                                                <div className="inPgBcHalfBlk">
                                                    <div className="inPgBcTextBlk">
                                                        <Link to="/loans"><div className="bcMainTxt">Loans</div></Link>
                                                        <div className="bcSubTxt">/ Loan Against Gold</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="bc_IIcol">
                                                <ul className="bcPointsSlanting">
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Best Value of your Gold with Lowest Interest</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Higher Loan Amount, Depending on Purity</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Instant Cash from a Wide Range of Schemes</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="loanInpg">
                            <div className="widthfix_10px">
                                <ul className="loanIIcolContainer">
                                    <li className="loanIIcolContainer">
                                        <div className="loanImgBlk">
                                            <div className="goldImage"></div>
                                        </div>
                                        <div className="glRatingAlign">
                                            <RatingStar />
                                        </div>
                                        <div className="glReviewLink"><Link to="/goldloanreview">Read/Write Reviews</Link></div>
                                    </li>
                                    <li className="loanIIcolContainer">
                                        <div className="loanContentBlk">
                                            <div className="loan_headTxt">Unlock the power of your<span className="headTxtRed"> gold</span></div>
                                            <div className="ratingSmallScreen">
                                                <RatingStar />
                                                <div className="glReviewLinkSmallScreen"><Link to="/goldloanreview">Read/Write Reviews</Link></div>
                                            </div>
                                            <div className="loan_heroTxt">Get cash in a flash with our glittering gold loans!</div>
                                            <ul className="loan_uspPoint">
                                                <li className="loan_uspPoint">Highest Loan Amount</li>
                                                <li className="loan_uspPoint">Minimal Documentation</li>
                                                <li className="loan_uspPoint">Attractive interest rates - <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;starts from <span className="txtRed">80* Paisa</span> per month <br /> <span className="noteCondition">* T&C applies</span></li>
                                                <li className="loan_uspPoint">Easy Processing</li>
                                            </ul>
                                            <div className="spacer_25margin_bottom"></div>
                                            <div className="uspPointSingleLine">
                                                <div className="uspPointSingleLineHead">Instant Gold Loan</div>
                                                <div className="uspPointSingleLineDesc">With our simple and easy documentation process, you can now walk-into any of our branch with your jewellery and can avail gold loan instantly.</div>
                                                <div className="uspPointSingleLineHead">Secure and Safe</div>
                                                <div className="uspPointSingleLineDesc">Your gold is absolutely safe with us. Moham Capital stores the gold in a secure vault with advanced security systems and round-the-clock surveillance.</div>
                                            </div>
                                            <Link to="/apply" onClick={(e) => OnLoanTypeSelected(e, 2, "Gold Loan")}><input type="button" className="loan_usp_button" value="Apply Now" /></Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;