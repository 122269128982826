import { React, Container, Link, Session, useNavigate } from "common";

const Component = () => {

    const Navigate = useNavigate();

    const OnLoanTypeSelected = (e, type, name) => {
        e.preventDefault();
        Session.Store('loanTypeId', type);
        Session.Store('loanTypeName', name);
        Navigate("/apply");
    }

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_bike">
                            <div className="inPgBcShadeBlk">
                                <div className="inPgBcCrossBlk">
                                    <div className="widthfix_10px">
                                        <ul className="bc_IIcol">
                                            <li className="bc_IIcol">
                                                <div className="inPgBcHalfBlk">
                                                    <div className="inPgBcTextBlk">
                                                        <Link to="/loans"><div className="bcMainTxt">Loans</div></Link>
                                                        <div className="bcSubTxt">/ Two Wheeler Loan</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="bc_IIcol">
                                                <ul className="bcPointsSlanting">
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Instant Approval with Paperless Process </li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Low Processing Fee and Low Interest Rate</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Zero Down Payment with Flexible Tenure</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="loanInpg">
                            <div className="widthfix_10px">
                                <ul className="loanIIcolContainer">
                                    <li className="loanIIcolContainer">
                                        <div className="loanImgBlk">
                                            <div className="bikeImage"></div>
                                        </div>
                                    </li>
                                    <li className="loanIIcolContainer">
                                        <div className="loanContentBlk">
                                            <div className="loan_headTxt">Looking for a <span className="headTxtRed"> new</span> motorcycle?</div>
                                            {/* <div className="loan_heroTxt"> Follow these steps to own the bike of your choice</div> */}
                                            <div className="loan_heroTxt">Ride in style with our hassle-free bike loan!</div>
                                            <ul className="loan_uspPoint">
                                                <li className="loan_uspPoint">Easy Online Application</li>
                                                <li className="loan_uspPoint">Minimal Documentation</li>
                                                <li className="loan_uspPoint">Simple Verification Process</li>
                                                <li className="loan_uspPoint">Money transferred to dealer</li>
                                            </ul>
                                            <Link to="/apply" onClick={(e) => OnLoanTypeSelected(e, 1, "Two Wheeler Loan")}><input type="button" className="loan_usp_button" value="Apply Now" /></Link>
                                        </div>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;